import React from "react";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// Props should include any props you want to pass to the button, including the click handler
interface CustomButtonProps {
  onClick: () => void;
  name: string;
}

const ButtonAdd: React.FC<CustomButtonProps> = ({ onClick, name }) => {
  return (
    <Button
      variant="contained"
      startIcon={<AddCircleOutlineIcon />}
      onClick={onClick}
      sx={{
        // minWidth: "180px",
        minHeight: "50px",
        maxHeight: "50px",
        bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
        color: (theme) => theme.palette.custom?.buttonColor,
      }}
    >
      {name}
    </Button>
  );
};

export default ButtonAdd;
