import * as Yup from "yup";

export default function validationSchema() {
  return Yup.object({
    name: Yup.string().required("Warehouse name is required"),
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    city: Yup.string().required("City is required"),
    zipCode: Yup.string().required("ZIP code is required"),
    // phone: Yup.string()
    //   .matches(/^\d+$/, "Phone number is not valid")
    //   .required("Phone number is required"),
    street: Yup.string().required("Street is required"),
    state: Yup.string().required("State is required"),
  });
}
