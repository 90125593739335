import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import { IconButton, Toolbar, PaletteMode } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { RootState } from "RTK/store";
import { useEffect, useState } from "react";
import { setUser } from "RTK/features/auth/authSlice";
// import { blue, green, red } from "@mui/material/colors";
import CryptoJS from "crypto-js";

type UserDataType = {
  // Define the structure of your user data here
  name: string;
  email: string;
  id?: any;
  // ... other fields
};

interface NavbarProps {
  userData: UserDataType;
  logOut: () => void;
  open: boolean;
  drawerWidth: number;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  setMyMode: (mode: PaletteMode) => void;
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerWidth: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open, drawerWidth }) => ({
  zIndex: theme.zIndex.drawer,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),

  ...(!open && {
    // marginLeft: '100px',
    width: `calc(100% - 65px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Navbar({
  userData,
  logOut,
  open,
  drawerWidth,
  handleDrawerOpen,
  handleDrawerClose,
  setMyMode,
}: NavbarProps) {
  const theme = useTheme();

  const dispatch = useDispatch();
  useEffect(() => {
    const encryptedEmail = localStorage.getItem("encryptedEmail");
    const decryptedEmail = encryptedEmail
      ? CryptoJS.AES.decrypt(
          encryptedEmail,
          "secret key 1oknvgudcxas23"
        ).toString(CryptoJS.enc.Utf8)
      : null;

    if (decryptedEmail) {
      dispatch(setUser({ firstName: decryptedEmail }));
    }
  }, [dispatch]);

  const userEmail = useSelector((state: RootState) => state.auth.firstName);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const oopen = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [notifAnchorEl, setNotifAnchorEl] = useState<null | HTMLElement>(null);
  const isNotifMenuOpen = Boolean(notifAnchorEl);

  const handleNotifMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNotifAnchorEl(event.currentTarget);
  };

  const handleNotifMenuClose = () => {
    setNotifAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        drawerWidth={drawerWidth}
        sx={{ bgcolor: theme.palette.custom.sidebarColor }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            color="inherit"
            edge="start"
            sx={{ marginRight: 5 }}
          >
            {open ? (
              <MenuIcon
                sx={{ bgcolor: theme.palette.custom?.sideIcon, color: "white" }}
              />
            ) : (
              <DoubleArrowIcon
                sx={{ bgcolor: theme.palette.custom?.sideIcon, color: "white" }}
              />
            )}
          </IconButton>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              [theme.breakpoints.down("md")]: {
                backgroundColor: red[500],
              },
              [theme.breakpoints.up("md")]: {
                backgroundColor: blue[500],
              },
              [theme.breakpoints.up("lg")]: {
                backgroundColor: green[500],
              },
            }}

            // color={theme.palette.testColor.primary}
            // bgcolor={{ md: "red" }}
          >
            Mini variant drawer
          </Typography> */}

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              aria-label="show notifications"
              color="inherit"
              onClick={handleNotifMenuOpen}
              sx={{ p: 0, mr: 2 }} // Adjust the right margin to space it out from the profile icon
            >
              <Badge badgeContent={2} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Menu
              anchorEl={notifAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={isNotifMenuOpen}
              onClose={handleNotifMenuClose}
              sx={{
                "& .MuiPaper-root": {
                  // This will target the paper component (the menu popover)
                  marginTop: "8px", // Add a small margin at the top of the menu for better visual separation
                  borderRadius: "8px", // Optionally round the corners of the menu
                  // You can add more styles to improve the appearance
                },
              }}
            >
              {/* Map over your notifications and create a MenuItem for each one */}
              <MenuItem onClick={handleNotifMenuClose}>Notification 1</MenuItem>
              <MenuItem onClick={handleNotifMenuClose}>Notification 2</MenuItem>
              {/* ... add more items here */}
            </Menu>

            <IconButton
              // aria-label="account of current user"
              // aria-controls="menu-appbar"
              // aria-haspopup="true"
              // onClick={handleMenu}
              color="inherit"
              sx={{ p: 0, mr: 1 }}
            >
              <AccountCircleIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={oopen}
              onClose={handleClose}
              sx={{
                "& .MuiPaper-root": {
                  // This will target the paper component (the menu popover)
                  marginTop: "8px", // Add a small margin at the top of the menu for better visual separation
                  borderRadius: "8px", // Optionally round the corners of the menu
                  // You can add more styles to improve the appearance
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  localStorage.setItem(
                    "currentMode",
                    theme.palette.mode === "dark" ? "light" : "dark"
                  );
                  setMyMode(theme.palette.mode === "light" ? "dark" : "light");
                }}
              >
                <ListItemIcon sx={{ m: 0 }}>
                  {theme.palette.mode === "dark" ? (
                    <DarkModeOutlinedIcon />
                  ) : (
                    <LightModeOutlinedIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    theme.palette.mode === "dark" ? "Dark Mode" : "Light Mode"
                  }
                />
              </MenuItem>

              <MenuItem onClick={logOut}>
                <ListItemIcon sx={{ m: 0 }}>
                  <LogoutIcon />
                  {/* <Brightness7Icon /> */}
                </ListItemIcon>
                <ListItemText primary="Log Out" />
              </MenuItem>
            </Menu>

            <Box
              onClick={handleMenu}
              sx={{
                pl: 0,
                ml: 0,
                borderRadius: "15px",
                display: "flex",
                alignItems: "center",
                // gap: "8px",
                cursor: "pointer",
                transition: "0.3s",
                "&:hover": {
                  pl: "12px",
                  backgroundColor: "rgba(255, 255, 255, 0.08)",
                  transform: "scale(1.01)",
                  boxShadow: "0 3px 5px 2px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <Typography
                // variant="text"
                component="span"
                color="inherit"
                sx={{
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {userEmail}
              </Typography>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                // onClick={handleMenu}
                color="inherit"
              >
                {oopen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
