import FormFields from "../FormFields";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import useSendData from "Components/Axios/SendData";
import { useFormik } from "formik";
import Loading from "Components/ErrorAndLoading/Loading";

type Types = {
  handleClose: () => void;
  onUpdateSuccess: any;
  selectedCategory: any;
  open: any;
};

export default function UpdatePurchase({
  onUpdateSuccess,
  selectedCategory,
  open,
  handleClose,
}: Types) {
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();

  const formatDateForInput = (dateString: any) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const YYYY = date.getFullYear();
    const MM = (date.getMonth() + 1).toString().padStart(2, "0");
    const DD = date.getDate().toString().padStart(2, "0");
    return `${YYYY}-${MM}-${DD}`;
  };

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      ...selectedCategory,
      date: formatDateForInput(selectedCategory?.date),

      // date: selectedCategory?.date || new Date().toISOString().split('T')[0],
      // warehouse: selectedCategory.warehouse.name,
      // vendor: selectedCategory.vendor.vendorName,
      warehouse: selectedCategory.warehouse.id,
      vendor: selectedCategory.vendor.id,
      id: selectedCategory.id,
      // vendorName: selectedCategory.vendorName,
      // email: selectedCategory.email,
      // phone: selectedCategory.phone,
      // notes: selectedCategory.notes,
      // group: selectedCategory.group,
    },
    // validationSchema,
    onSubmit: async (values) => {
      const submittedData = await sendData({
        endpoint: `purchases/${selectedCategory.id}`,
        data: values,
        isJson: true,
        method: "put",
      });
      if (submittedData && submittedData.purchase) {
        onUpdateSuccess((prevTransfers: any) =>
          prevTransfers.map((transfer: any) =>
            transfer.id === submittedData.purchase.id
              ? submittedData.purchase
              : transfer
          )
        );
        handleClose();
      }
    },
  });

  const errorToDisplay = sendError;
  return (
    <>
      <>
        <ErrorDisplay error={errorToDisplay} />
        <Loading isLoading={sendLoading} />
      </>
      <FormFields
        formik={formik}
        isSubmitting={formik.isSubmitting}
        handleClose={handleClose}
        open={open}
        isEdit={true}
        formTitle={"Update Purchase"}
        saveButtonLabel={"Update Purchase"}
      />
    </>
  );
}
