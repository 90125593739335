import { TextField } from "@mui/material";
import useSendData from "Components/Axios/SendData";
// import { AllowToNumberOnly } from "Components/AllowToNumberOnly/AllowToNumberOnly";
// import CustomTextField from "Components/CustomTextField/CustomTextField";
import ReusableDialog from "Components/Dialog/Dialog";
import { Autocomplete } from "@mui/lab";
import CustomPhoneInput from "Components/CustomPhoneInput/CustomPhoneInput";

export interface Types {
  formik: any;
  isSubmitting: boolean;
  handleClose: () => void;
  formTitle: string;
  saveButtonLabel: string;
  isEdit?: any;
  open: any;
}

const allowedStates = [
  "Alaska",
  "Alabama",
  "Arkansas",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Massachusetts",
  "Maryland",
  "Maine",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Mississippi",
  "Montana",
  "North Carolina",
  "North Dakota",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "Nevada",
  "New York",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "Vermont",
  "Washington",
  "Wisconsin",
  "West Virginia",
  "Wyoming",
];

const FormFields: React.FC<Types> = ({
  formik,
  isSubmitting,
  handleClose,
  formTitle,
  saveButtonLabel,
  isEdit,
  open,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sendData, error, isLoading: sendLoading } = useSendData();
  // const [isImagesSelected, setIsImagesSelected] = useState(false);

  // const handleNumberChange = (fieldName: string, decimal: boolean = false) =>
  //   AllowToNumberOnly(
  //     (name: string, value: any) => {
  //       formik.setFieldValue(name, value);
  //     },
  //     fieldName,
  //     decimal
  //   );



  return (
    <>
      <ReusableDialog
        formTitle={formTitle}
        formik={formik}
        isSubmitting={isSubmitting}
        saveButtonLabel={saveButtonLabel}
        isEdit={isEdit}
        onClose={handleClose}
        open={open}
        // onClose={handleCloseDialog}
      >
        <>
          {/* <CustomTextField
            name="companyName"
            label="Company Name"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={formik.touched.companyName && formik.errors.companyName}
          /> */}

          <TextField
            margin="normal"
            fullWidth
            id="name"
            label="Warehouse Name"
            name="name"
            autoFocus
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={
              formik.touched.name && typeof formik.errors.name === "string"
                ? formik.errors.name
                : undefined
            }
            sx={{ flex: "1 0 45%" }}
          />
          <TextField
            margin="normal"
            fullWidth
            id="street"
            label="Street"
            name="street"
            value={formik.values.street}
            onChange={formik.handleChange}
            error={formik.touched.street && Boolean(formik.errors.street)}
            helperText={
              formik.touched.street && typeof formik.errors.street === "string"
                ? formik.errors.street
                : undefined
            }
            sx={{ flex: "1 0 45%" }}
          />

          <Autocomplete
            id="state"
            options={allowedStates}
            getOptionLabel={(option) => option}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                fullWidth
                label="Choose a state"
                variant="outlined"
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={
                  formik.touched.state && formik.errors.state
                    ? formik.errors.state
                    : ""
                }
              />
            )}
            value={formik.values.state}
            onChange={(event, value) => {
              formik.setFieldValue("state", value);
            }}
            fullWidth
            sx={{ flex: "1 0 45%" }}
          />

          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={
              formik.touched.email && typeof formik.errors.email === "string"
                ? formik.errors.email
                : undefined
            }
            sx={{ flex: "1 0 45%" }}
          />
          <TextField
            margin="normal"
            fullWidth
            id="city"
            label="City"
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={
              formik.touched.city && typeof formik.errors.city === "string"
                ? formik.errors.city
                : undefined
            }
            sx={{ flex: "1 0 45%" }}
          />
          <TextField
            margin="normal"
            fullWidth
            id="zipCode"
            label="ZIP Code"
            name="zipCode"
            value={formik.values.zipCode}
            onChange={formik.handleChange}
            error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
            helperText={
              formik.touched.zipCode &&
              typeof formik.errors.zipCode === "string"
                ? formik.errors.zipCode
                : undefined
            }
            sx={{ flex: "1 0 45%" }}
          />
          {/* <TextField
            margin="normal"
            fullWidth
            id="phone"
            label="Phone Number"
            name="phone"
            value={formik.values.phone}
            // onChange={formik.handleChange}
            // onChange={handleNumberChange("phone")}
            onChange={handlePhoneChange}
            onBlur={handlePhoneChange}
            // onBlur={(e) => {
            //   formik.setFieldValue(
            //     "phone",
            //     formatPhoneNumber(e.target.value)
            //   );
            // }}

            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={
              formik.touched.phone && typeof formik.errors.phone === "string"
                ? formik.errors.phone
                : undefined
            }
            sx={{ width: "100%" }}
          /> */}

          <CustomPhoneInput
            formik={formik}
            name="phone"
            value={formik.values.phone}
            label="Phone Number"
          />
        </>
      </ReusableDialog>
    </>
  );
};

export default FormFields;
