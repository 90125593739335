import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FetchData from "Components/Axios/FetchData";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";

type SupplyDetailsProps = {
  handleClose: () => void;
  open: boolean;
  purchaseId: any;
};

const PurchaseDetails: React.FC<SupplyDetailsProps> = ({
  open,
  handleClose,
  purchaseId,
}) => {
  const [purchaseData, setPurchaseData] = useState(null);
  // const [qrCodeUrl, setQrCodeUrl] = useState("");

  const {
    data: fetchedData,
    error: fetchError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading,
  } = FetchData(`purchases/${purchaseId}`);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [supplies, setSupplies] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (fetchedData) {
      setPurchaseData(fetchedData);
    } else if (fetchError) {
      console.error(`Error loading purchase details: ${fetchError}`);
    }
  }, [fetchedData, fetchError, purchaseId]);

  if (!purchaseData) return <div>Loading...</div>;
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  if (!purchaseData) {
    return <div>No purchase data found</div>;
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent>
        <IconButton onClick={handleClose} style={{ float: "right" }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom textAlign={"center"}>
          Purchase Details
        </Typography>
        <Typography variant="subtitle1">
          <strong>Date:</strong>{" "}
          {new Date(purchaseData.purchase.date).toLocaleString()}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Vendor:</strong> {purchaseData.purchase.vendor.vendorName}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Warehouse:</strong> {purchaseData.purchase.warehouse.name}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Note:</strong> {purchaseData.purchase.notes}
        </Typography>

        <Typography variant="h6">Products:</Typography>
        <List dense>
          {purchaseData.purchase.product.map((item: any) => (
            <ListItem key={item.id}>
              <ListItemText
                primary={item.name}
                secondary={`Quantity: ${item.quantity}, Price: ${item.price}, Total: ${item.total}`}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};
export default PurchaseDetails;
