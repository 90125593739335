import React, { useState, useEffect } from "react";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
// import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { baseUrl } from "utils/baseUrl";
// import AddWarehouse from "./AddUser/AddUser";
import { toast } from "react-toastify";
import { GridRenderCellParams } from "@mui/x-data-grid";
// import DeleteWarehouse from "./DeleteUser/DeleteUser";
import AddUser from "./AddUser/AddUser";
import DeleteUser from "./DeleteUser/DeleteUser";
import { UserTypes } from "./types/types";
import EditUser from "./EditUser/EditUser";
import ReusableTable from "Components/Table/Table";
import FetchData from "Components/Axios/FetchData";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  // username: string;
  email: string;
  phone: string;
  isActive: boolean;

  role?: any;
  accessWarehouses?: any;
}

export default function UsersManagement() {
  const [users, setUsers] = useState<UserTypes[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedUser, setSelectedUser] = useState<UserTypes | null>(null);
  const [warehouseToDelete, setWarehouseToDelete] = useState<User | null>(null);
  // const [userToDelete, setUserToDelete] = useState<UserTypes | null>(null);

  const [editingWarehouse, setEditingWarehouse] = useState(null);

  const [allWarehouses, setAllWarehouses] = useState([]);
  // const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sidebarIsOpen, setSidebarIsOpen] = useState(true);

  const { data, error: usersError, isLoading } = FetchData("users");

  useEffect(() => {
    if (data) {
      if (Array.isArray(data)) {
        setUsers(data);
      } else {
        setError("Data is not in expected format");
        // setUsers([]);
      }
    }
  }, [data]);

  const handleOpenDialog = () => {
    setSelectedUser(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const handleDeleteClick = (userId: string) => {
    const userIdStr = userId.toString();
    const user = users.find((usr) => usr.id.toString() === userIdStr);

    if (user) {
      setWarehouseToDelete(user);
      setDeleteDialogOpen(true);
    } else {
      toast.error("User not found.");
    }
  };

  const handleDeleteUser = async (userId: string) => {
    const token = localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios.delete(`${baseUrl}/users/${userId}`, config);
      setUsers(
        users.filter((user) => user.id.toString() !== userId.toString())
      );
      setDeleteDialogOpen(false);
      toast.success("User deleted successfully!");
    } catch (error) {
      const errorMessage = (error as Error).message;
      toast.error("Error deleting user: " + errorMessage);
    }
  };

  const confirmDelete = () => {
    if (warehouseToDelete?.id) {
      handleDeleteUser(warehouseToDelete.id.toString());
    } else {
      toast.error("Error: No user selected for deletion.");
    }
  };

  const handleEdit = async (userId: string) => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await axios.get(`${baseUrl}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userData = response.data;
      setEditingWarehouse(userData);
      setIsEditModalOpen(true);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data.");
    }
  };

  const handleChangeStatus = async (userId: any, newStatus: any) => {
    const token = localStorage.getItem("userToken");
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await axios.patch(
        `${baseUrl}/users/${userId}`,
        { isActive: newStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, isActive: newStatus } : user
        )
      );
      toast.success("User status updated successfully!");
    } catch (error: any) {
      toast.error("Error updating user status.");
    }
  };

  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const response = await axios.get(`${baseUrl}/warehouses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setAllWarehouses(response.data);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          // Handle the error response from your API
          toast.error(
            `Error fetching warehouses: ${error.response.data.message}`
          );
        } else {
          // Handle unexpected errors
          toast.error(
            "An unexpected error occurred while fetching warehouses."
          );
        }
      }
    };

    fetchWarehouses();
  }, []);

  const columns: GridColDef[] = [
    { field: "firstName", headerName: "First Name", flex: 1 },
    { field: "lastName", headerName: "Last Name", flex: 1 },
    // { field: "username", headerName: "Username", width: 150 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone", headerName: "Phone", flex: 1 },
    // { field: "isActive", headerName: "Active", width: 100 },
    {
      field: "isActive",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <FormControlLabel
          control={
            <Switch
              checked={params.value === "Yes"}
              onChange={() =>
                handleChangeStatus(params.id, params.value !== "Yes")
              }
            />
          }
          label={params.value === "Yes" ? "Active" : "Inactive"}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {/* <VisibilityIcon
            onClick={() => handleViewDetails(params.row)}
            style={{ cursor: "pointer", marginRight: 10 }}
          /> */}
          {/* {warehouses.map((warehouses) => (
            <div key={warehouses.id} >
              <IconButton >
                <EditIcon onClick={() => handleEdit(warehouses)}/>
              </IconButton>
              
            </div>
          ))} */}

          <IconButton>
            <EditIcon
              onClick={() => {
                handleEdit(String(params.id));
              }}
            />
          </IconButton>
          <IconButton onClick={() => handleDeleteClick(String(params.id))}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const filteredRows = Array.isArray(users)
    ? users
        .filter((user) => {
          const searchLower = searchText.toLowerCase();
          return (
            user?.firstName?.toLowerCase().includes(searchLower) ||
            user?.lastName?.toLowerCase().includes(searchLower) ||
            user?.email?.toLowerCase().includes(searchLower)
          );
        })
        .map((user) => {
          if (!user) return null;
          return {
            id: user.id,
            firstName: user.firstName ?? "N/A",
            lastName: user.lastName ?? "N/A",
            email: user.email ?? "N/A",
            phone: user.phone ?? "N/A",
            isActive: user.isActive ? "Yes" : "No",
            role: user.role ?? "N/A",
            accessWarehouses: user.accessWarehouses
              ? user.accessWarehouses
                  .map((whId) => {
                    const warehouse = allWarehouses.find(
                      (wh: any) => wh.id === whId
                    );
                    return warehouse ? warehouse.name : "Unknown";
                  })
                  .join(", ")
              : "N/A",
          };
        })
        .filter((row) => row !== null)
    : []; // Fallback to an empty array if users is not an array

  // console.log("==========filteredRows==============");
  // console.log(filteredRows);
  // console.log("====================================");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const rows = users.map((user: UserTypes) => ({
  //   id: user.id,
  //   firstName: user?.firstName ?? "N/A", // Use optional chaining
  //   lastName: user?.lastName ?? "N/A", // Use optional chaining
  //   // username: user?.username ?? "N/A", // Use optional chaining
  //   email: user?.email ?? "N/A", // Use optional chaining
  //   phone: user?.phone ?? "N/A", // Use optional chaining
  //   isActive: user.isActive ? "Yes" : "No", // Use optional chaining
  // }));

  // console.log("==========-----=================");
  // console.log(rows);
  // console.log("====================================");

  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
  };

  // if (warehouses.length === 0) {
  //   return <p>No warehouses found.</p>;
  // }
  // if (!users) {
  //   return <div>Loading...</div>; // or any other fallback UI
  // }

  // if (error) {
  //   return (
  //     <Box>
  //       <Typography color="error">Error: {error}</Typography>
  //       {/* Add buttons or links to retry or go back to another page */}
  //     </Box>
  //   );
  // }

  return (
    <Box>
      <Typography variant="h4" fontWeight="medium">
        Users Management
      </Typography>
      <>
        <ErrorDisplay error={usersError || error} />
        {/* <Loading isLoading={isLoading} /> */}
      </>

      <ReusableTable
        searchText={searchText}
        onSearchChange={handleSearchChange}
        onAddButtonClick={handleOpenDialog}
        rows={filteredRows}
        columns={columns}
        loading={isLoading}
        addButtonName="Add New User"
      />

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
            color: (theme) => theme.palette.custom?.dialogColor,
          }}
        >
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Add New User</Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseDialog}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent>
            <AddUser handleClose={handleCloseDialog} setUsers={setUsers} />
          </DialogContent>
        </Box>
      </Dialog>

      <Dialog
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
            color: (theme) => theme.palette.custom?.dialogColor,
          }}
        >
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6"> Edit User</Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setIsEditModalOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent>
            {editingWarehouse && (
              <EditUser
                user={editingWarehouse}
                handleClose={() => setIsEditModalOpen(false)}
                setUsers={setUsers}
              />
            )}
          </DialogContent>
        </Box>
      </Dialog>

      <DeleteUser
        show={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        handleConfirm={confirmDelete}
        warehouseName={
          warehouseToDelete
            ? `${warehouseToDelete.firstName} ${warehouseToDelete.lastName}`
            : ""
        }
      />
    </Box>
  );
}
