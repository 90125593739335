import * as Yup from "yup";

export default function validationSchema() {
  return Yup.object({
    date: Yup.date().required("Date is required"),
    // fromWarehouse: Yup.string().required("From Warehouse is required"),
    // toWarehouse: Yup.string().required("To Warehouse is required"),
    // supplies: Yup.array().of(Yup.string()).required("Supplies are required"),
    // equipments: Yup.array().of(Yup.string()).required("Equipments are required"),

    // quantity: Yup.number().min(1).required("Quantity is required"),
    // status: Yup.string().required("Status is required"),
    note: Yup.string().max(500, "Note must be less than 500 characters"),
  });
}
