// export const baseUrl = "http://localhost:8000/api/v1";
export var baseUrl: any;

if (process.env.NODE_ENV === "development") {
  baseUrl = process.env.REACT_APP_BASE_URL_LOCAL;
} else {
  baseUrl = process.env.REACT_APP_BASE_URL;
}


// REACT_APP_BASE_URL=https://inventory-7p0p.onrender.com/api/v1

// REACT_APP_BASE_URL_LOCAL=http://localhost:8000/api/v1
