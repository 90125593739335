import axios from "axios";
import { useParams } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { FormDataHelper } from "pages/Supplies/FormDataHelper";
import { toast } from "react-toastify";
import { baseUrl } from "utils/baseUrl";

interface Category {
  _id: string;
  name: string;
}

const Test = () => {
  const { id } = useParams();
  const [supply, setSupply] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const { supplyId } = useParams<{ supplyId: string }>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [name, setName] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([]);
  //-----------
  const handleCategoryChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/categories`
        );
        const data = await response.json();
        if (data.message === "success" && Array.isArray(data.category)) {
          setCategories(data.category);
        } else {
          console.error("Fetched data is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    if (supplyId) {
      fetchCategories();
    }
  }, [supplyId]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedImageFiles(Array.from(event.target.files));
    }
  };

  //--------------------------- يعمل جيدا
  // const handleUpdateSupply = () => {
  //   // بناء جسم الطلب (body) بناءً على ما يتوقعه الـ backend
  //   const body = {
  //     name: name, // أو قيمة أخرى تريد تحديثها
  //     category: selectedCategories, // هذا يجب أن يكون مصفوفة، حتى لو كانت فارغة
  //   };

  //   // معرف المعدات الثابت للمثال
  //   const fixedSupplyId = "658acd63caa94c846db32446";

  //   // إرسال طلب PUT مع JSON body
  //   axios
  //     .put(`http://localhost:8000/api/v1/equipments/${fixedSupplyId}`, body)
  //     .then((response) => {
  //       toast.success("Supply updated successfully");
  //     })
  //     .catch((error) => {
  //       console.error("Error updating supply:", error);
  //       toast.error("Error updating supply");
  //     });
  // };
  //---------------------------

  // const handleUpdateSupply = () => {
  //   const fixedSupplyId = "658acd63caa94c846db32446";
  //   const url = `http://localhost:8000/api/v1/equipments/${fixedSupplyId}`;

  //   if (selectedCategories.length === 0) {
  //     // إرسال JSON body
  //     const body = {
  //       name: name,
  //       category: [] as string[],
  //     };

  //     axios
  //       .put(url, body, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       })
  //       .then((response) => {
  //         toast.success("Supply updated successfully");
  //       })
  //       .catch((error) => {
  //         console.error("Error updating supply:", error);
  //         toast.error("Error updating supply");
  //       });
  //   } else {
  //     // إرسال FormData
  //     const formData = new FormData();
  //     formData.append("name", name);
  //     selectedCategories.forEach((category) => {
  //       formData.append("category", category);
  //     });

  //     axios
  //       .put(url, formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((response) => {
  //         toast.success("Supply updated successfully");
  //       })
  //       .catch((error) => {
  //         console.error("Error updating supply:", error);
  //         toast.error("Error updating supply");
  //       });
  //   }
  // };

  const handleUpdateSupply = () => {
    const fixedSupplyId = "658acd63caa94c846db32446";
    const url = `http://localhost:8000/api/v1/equipments/${fixedSupplyId}`;

    // تحقق إذا كانت هناك صورة للإرسال
    const hasImage = Boolean(selectedImage);

    if (selectedCategories.length === 0 && !hasImage) {
      // إرسال JSON body
      const body = {
        name: name,
        // لا تضيف category أو images إذا كانت غير مطلوبة
      };
      axios.put(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // ... التعامل مع الرد والأخطاء كما في السابق
    } else {
      // إرسال FormData
      const formData = new FormData();
      formData.append("name", name);

      // إضافة التصنيفات إذا كانت موجودة
      if (selectedCategories.length > 0) {
        selectedCategories.forEach((category) => {
          formData.append("category", category);
        });
      }

      // لإضافة مصفوفة من الصور
      // ...
      if (selectedImageFiles.length > 0) {
        selectedImageFiles.forEach((file, index) => {
          formData.append(`images[${index}]`, file);
        });
      }
      // ...

      axios.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // ... التعامل مع الرد والأخطاء كما في السابق
    }
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/categories`)
      .then((response) => {
        // console.log('====================================');
        // console.log(response.data.category);
        // console.log('====================================');
        setSupply(response.data.category);
        setIsLoading(false);
        // const data = response.json();
        if (
          response.data.message === "success" &&
          Array.isArray(response.data.category)
        ) {
          setCategories(response.data.category);
        } else {
          console.error("Fetched data is not an array:", response.data);
        }
      })
      .catch((error) => {
        setError(error.toString());
        setIsLoading(false);
      });
  }, [id]);

  // useFormik لإدارة النموذج
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const formik = useFormik({
    initialValues: {
      name: supply?.name || "",
      description: supply?.description || "",
      // ... أي حقول أخرى تحتاج إلى تعديلها
    },
    // validationSchema: validationSchema,
    enableReinitialize: true, // لإعادة تهيئة القيم عندما تتغير الـ supply
    onSubmit: (values) => {
      const formData = FormDataHelper(values);
      const fixedSupplyId = "658acd63caa94c846db32446"; // هذه هي القيمة الثابتة لـ supplyId
      axios
        .put(
          `http://localhost:8000/api/v1/equipments/${fixedSupplyId}`,
          formData
        )
        .then((response) => {
          toast.success("Supply updated successfully");
        })
        .catch((error) => {
          toast.error("Error updating supply");
        });
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1>test test 1</h1>
      <input accept="image/*" type="file" onChange={handleImageChange} />
      <TextField
        fullWidth
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        margin="normal"
      />

      <FormControl fullWidth>
        <InputLabel id="category-dropdown-label">Select Category</InputLabel>
        <Select
          labelId="category-dropdown-label"
          id="category-dropdown"
          multiple
          value={selectedCategories}
          onChange={handleCategoryChange}
          renderValue={(selected) => selected.join(", ")}
        >
          {categories.map((category) => (
            <MenuItem key={category._id} value={category._id}>
              <Checkbox
                checked={selectedCategories.indexOf(category._id) > -1}
              />
              <ListItemText primary={category.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button onClick={handleUpdateSupply} variant="contained" color="primary">
        Update Supply
      </Button>
    </div>
  );
};

export default Test;
