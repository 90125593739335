import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Box,
  useTheme,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FormikProps } from "formik";

interface CustomPhoneInputProps {
  formik: FormikProps<any>;
  value?: any;
  name?: string;
  label?: string;
  country?: string;
  onlyCountries?: string[];
  preferredCountries?: string[];
  sx?: object;
  inputStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}

const CustomPhoneInput: React.FC<CustomPhoneInputProps> = ({
  formik,
  value,
  name = "phone",
  label = "Phone Number",
  country = "us",
  onlyCountries = ["us"],
  preferredCountries = ["us"],
  sx = {},
  inputStyle = {},
  buttonStyle = {},
  containerStyle = {},
}) => {
  const theme = useTheme();
  return (
    <FormControl
      variant="outlined"
      fullWidth
      sx={{ mt: 2, width: "100%", ...sx }}
    >
      <InputLabel
        shrink
        htmlFor={name}
        // sx={{
        //   color:
        //     formik.touched[name] && formik.errors[name] ? "#f44336" : "#9b1717",
        //   // backgroundColor: "#0f1824",
        //   backgroundColor: "transparent",
        //   // backgroundColor: "none",
        //   ml: 4,
        //   px: 1,
        //   '&.Mui-focused': {
        //     backgroundColor: 'inherit',
        //   }
        // }}
        sx={{
          // color:
          //   formik.touched[name] && formik.errors[name] ? "#f44336" : "#9b1717",
          ml: 4,
          px: 1,
          // backgroundColor: theme.palette.custom.dialogBgColor,
          // backgroundColor: theme.palette.background.paper,
          // backgroundColor:"#394757",
          backgroundColor:
            theme.palette.mode === "light"
              ? theme.palette.background.paper
              : "#394757",
        }}
      >
        {label}
      </InputLabel>
      <Box sx={{ display: "flex", alignItems: "center", mt: 0 }}>
        <PhoneInput
          country={country}
          onlyCountries={onlyCountries}
          preferredCountries={preferredCountries}
          value={value}
          onChange={(value) => formik.setFieldValue(name, value)}
          inputProps={{
            name: name,
            required: true,
            autoFocus: true,
            id: name,
            style: {
              // width: "calc(100% - 16px)",
              width: "100%",
              height: "56px",
              fontSize: "16px",
              fontWeight: "400",
              padding: "16.5px 0px 16.5px 48px",
              borderRadius: "4px",
              border:
                formik.touched[name] && formik.errors[name]
                  ? "1px solid #f44336"
                  : "1px solid #ddd",
              // color: "#ddd",
              color: theme.palette.mode === "light" ? "#424242" : "white",
              // backgroundColor: "#0f1824",
              backgroundColor: "inherit",
              boxSizing: "border-box",
              ...inputStyle,
            },
          }}
          buttonStyle={{
            borderRadius: "4px 0 0 4px",
            border:
              formik.touched[name] && formik.errors[name]
                ? "1px solid #f44336"
                : "1px solid #ddd",
            color: "#9b1717",
            // backgroundColor: "#0f1824",
            backgroundColor: "inherit",
            ...buttonStyle,
          }}
          containerStyle={{
            ...containerStyle,
          }}
        />
      </Box>
      {formik.touched[name] && formik.errors[name] && (
        <FormHelperText error sx={{ marginTop: "8px" }}>
          {String(formik.errors[name])}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomPhoneInput;
