import React from "react";
import { InputAdornment, TextField } from "@mui/material";
// import { useField } from 'formik';

interface CustomTextFieldProps {
  name: string;
  label: string;
  value: any;
  onChange: any;
  error: any;
  helperText: any;
  type?: string;
  multiline?: boolean;
  rows?: number;
  onBlur?:any
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  name,
  label,
  value,
  onChange,
  error,
  helperText,
  multiline,
  rows,
  type,
  onBlur
}) => {
  const shouldShowUnit =
    value && (name === "minValue" || name === "quantity") ? "Unit" : null;

  return (
    <TextField
      fullWidth
      id={name}
      name={name}
      label={label}
      multiline={multiline}
      rows={rows || 1}
      value={value}
      onChange={onChange}
      error={error}
      onBlur={onBlur} 
      helperText={helperText}
      margin="normal"
      type={type}
      InputProps={{
        endAdornment: shouldShowUnit && (
          <InputAdornment position="end">{shouldShowUnit}</InputAdornment>
        ),
        startAdornment:
          value && name === "price" ? (
            <InputAdornment position="start">$</InputAdornment>
          ) : null,
      }}
      sx={{ flex: "1 0 45%" }}
    />
  );
};

export default CustomTextField;
