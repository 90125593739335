import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Container,
  Box,
  CssBaseline,
  Switch,
  SelectChangeEvent,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import { baseUrl } from "utils/baseUrl";
// import { UserTypes } from "../types/types";
import axios from "axios";
// import { AxiosError } from "axios";
import { useLocation } from "react-router-dom";
// import { styled } from "@mui/material/styles";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import React, { useEffect, useState } from "react";
// import { WarehouseTypes } from "pages/Warehouses/types/types";
import {
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CustomPhoneInput from "Components/CustomPhoneInput/CustomPhoneInput";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface EditUserProps {
  user: UserTypes;
  handleClose: () => void;
  setUsers: React.Dispatch<React.SetStateAction<UserTypes[]>>;
}

export interface UserTypes {
  id: string;
  firstName: string;
  lastName: string;
  // username: string;
  email: string;
  phone: string;
  isActive: boolean;
  password: string;
  role: string;
  // profilePic: File | null;
  accessWarehouses: any;

  name?: string;
}

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  // username: Yup.string().required("Username is required"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  phone: Yup.string()
    .matches(/^\d+$/, "Phone number is not valid")
    .required("Phone number is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  role: Yup.string().required("Role is required"),
  isActive: Yup.boolean().required("Status is required"),
  // accessWarehouses: Yup.array().of(Yup.string()),
});

// The EditWarehouse component
export default function EditUser({
  user,
  handleClose,
  setUsers,
}: EditUserProps) {
  const [warehouses, setWarehouses] = useState<UserTypes[]>([]);
  const [warehouseNames, setWarehouseNames] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [personName, setPersonName] = React.useState<string[]>([]);
  // const [users, setUser] = useState<UserTypes | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const warehouseFromLocation = location.state?.user;

  // console.log("===========user=+++++++**================");
  // console.log(user);
  // console.log("====================================");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const response = await axios.get(`${baseUrl}/users/${user.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log("============response========================");
        // console.log(response);
        // console.log("====================================");

        // setUsers(response.data);

        // Set initial warehouse selection
        const selectedWarehouses = response.data.accessWarehouses.map(
          (wh: any) => wh.name
        );
        setPersonName(selectedWarehouses);
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Failed to load user data.");
      }
    };

    const fetchWarehouses = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const response = await axios.get(`${baseUrl}/warehouses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setWarehouses(response.data);
        setWarehouseNames(
          response.data.map((warehouse: any) => warehouse.name)
        );
      } catch (error) {
        toast.error("Failed to load warehouses.");
      }
    };

    fetchUserData();
    fetchWarehouses();
  }, [user.id]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === "all") {
      if (selectAll) {
        setSelectAll(false);
        setPersonName([]);
        formik.setFieldValue("accessWarehouses", []);
      } else {
        setSelectAll(true);
        setPersonName(warehouseNames);
        formik.setFieldValue(
          "accessWarehouses",
          warehouses.map((wh) => wh.id)
        );
      }
    } else {
      setSelectAll(false);
      const nextSelectedWarehouses =
        typeof value === "string" ? value.split(",") : value;
      setPersonName(nextSelectedWarehouses);
      const selectedWarehouseIds = nextSelectedWarehouses
        .map((name) => {
          const warehouse = warehouses.find((wh) => wh.name === name);
          return warehouse ? warehouse.id : null;
        })
        .filter((v): v is string => v !== null);
      formik.setFieldValue("accessWarehouses", selectedWarehouseIds);
    }
  };

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     const token = localStorage.getItem("userToken");
  //     try {
  //       const response = await axios.get(`${baseUrl}/warehouses`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       setWarehouses(response.data);

  //       const warehouseNames = response.data.map(
  //         (warehouse: any) => warehouse.name
  //       );
  //       setWarehouseNames(warehouseNames);
  //     } catch (error) {
  //       toast.error("Failed to load warehouses.");
  //     }
  //   };

  //   fetchUsers();
  // }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      password: user.password || "",
      isActive: Boolean(user.isActive),
      role: user.role,
      // accessWarehouses: user.accessWarehouses,
      accessWarehouses: user.accessWarehouses.map((wh: any) => wh.id),
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const { setSubmitting } = formikHelpers;
      setSubmitting(true);

      let updatedWarehouseIds = [];
      if (Array.isArray(values.accessWarehouses)) {
        updatedWarehouseIds = values.accessWarehouses;
      } else if (typeof values.accessWarehouses === "string") {
        updatedWarehouseIds = values.accessWarehouses
          .split(",")
          .map((name) => {
            const warehouse = warehouses.find((wh) => wh.name === name.trim());
            return warehouse ? warehouse.id : null;
          })
          .filter((id) => id !== null);
      }

      const updatedValues = {
        ...values,
        accessWarehouses: updatedWarehouseIds,
      };

      const token = localStorage.getItem("userToken");
      try {
        const response = await axios.patch(
          `${baseUrl}/users/${user.id}`,
          updatedValues,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // console.log("Server Response:", response.data);
        const updatedUser = response.data;
        setUsers((prevUsers) =>
          prevUsers.map((u) => (u.id === user.id ? updatedUser : u))
        );

        // console.log("====================================");
        // console.log(response.data.updatedUser);
        // console.log("====================================");

        toast.success("User updated successfully!");
        handleClose();
      } catch (error) {
        console.error("PUT Request Error:", error);
        toast.error("An error occurred while updating the user.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    if (checked) {
      setPersonName(warehouseNames);
      formik.setFieldValue(
        "accessWarehouses",
        warehouses.map((wh) => wh.id)
      );
    } else {
      setPersonName([]);
      formik.setFieldValue("accessWarehouses", []);
    }
  };

  const roles = ["admin", "user"];

  // useEffect(() => {
  //   if (Array.isArray(user.accessWarehouses)) {
  //     const warehouseNames = user.accessWarehouses.map((wh) => {
  //       const warehouse = warehouses.find((warehouse) => warehouse.id === wh.id);
  //       return warehouse ? warehouse.name : 'Unknown';
  //     });
  //     setPersonName(warehouseNames);
  //   } else if (user.accessWarehouses && typeof user.accessWarehouses === 'object') {
  //     const warehouse = warehouses.find((wh) => wh.id === user.accessWarehouses.id);
  //     setPersonName(warehouse ? [warehouse.name] : ['Unknown']);
  //   } else {
  //     setPersonName([]);
  //   }
  // }, [user.accessWarehouses, warehouses]);

  // useEffect(() => {
  //   if (Array.isArray(user.accessWarehouses)) {
  //     const warehouseNames = user.accessWarehouses
  //       .map((whId) => {
  //         const warehouse = warehouses.find((wh) => wh.id === whId);
  //         return warehouse ? warehouse.name : "Unknown";
  //       })
  //       .filter((name) => name !== "Unknown");

  //     setPersonName(warehouseNames);
  //   } else if (typeof user.accessWarehouses === "string") {
  //     setPersonName(user.accessWarehouses.split(", "));
  //   }
  // }, [warehouses, user.accessWarehouses]);

  // useEffect(() => {
  //   if (user) {
  //     formik.setValues({
  //       id: user.id,
  //       firstName: user.firstName,
  //       lastName: user.lastName,
  //       email: user.email,
  //       phone: user.phone,
  //       password: "", // Handle password differently as needed
  //       isActive: user.isActive,
  //       role: user.role,
  //       accessWarehouses: user.accessWarehouses.map((wh: any) => wh.id),
  //     });
  //   }
  // }, [user]);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          // marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          {/* First Name */}
          <TextField
            margin="normal"
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            autoFocus
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            // helperText={formik.touched.firstName ? formik.errors.firstName : ""}

            helperText={
              formik.touched.firstName &&
              typeof formik.errors.firstName === "string"
                ? formik.errors.firstName
                : undefined
            }
            sx={{ flex: "1 0 45%" }}
          />
          {/* Last Name */}
          <TextField
            margin="normal"
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            // helperText={formik.touched.lastName ? formik.errors.lastName : ""}
            helperText={
              formik.touched.lastName &&
              typeof formik.errors.lastName === "string"
                ? formik.errors.lastName
                : undefined
            }
            sx={{ flex: "1 0 45%" }}
          />
          {/* Username */}
          {/* <TextField
            margin="normal"
            fullWidth
            id="username"
            label="Username"
            name="username"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            // helperText={formik.touched.username ? formik.errors.username : ""}
            helperText={
              formik.touched.username &&
              typeof formik.errors.username === "string"
                ? formik.errors.username
                : undefined
            }
            sx={{ flex: "1 0 45%" }}
          /> */}

          {/* Email */}
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            // helperText={formik.touched.email ? formik.errors.email : ""}
            helperText={
              formik.touched.email && typeof formik.errors.email === "string"
                ? formik.errors.email
                : undefined
            }
            sx={{ flex: "1 0 45%" }}
          />

          {/* Phone */}
          {/* <TextField
            margin="normal"
            fullWidth
            id="phone"
            label="Phone Number"
            name="phone"
            value={formik.values.phone}
            // onChange={formik.handleChange}
            onChange={handleNumberChange("phone")}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            // helperText={formik.touched.phone ? formik.errors.phone : ""}

            helperText={
              formik.touched.phone && typeof formik.errors.phone === "string"
                ? formik.errors.phone
                : undefined
            }
            sx={{ flex: "1 0 45%" }}
          /> */}

          <CustomPhoneInput
            formik={formik}
            name="phone"
            value={formik.values.phone}
            label="Phone Number"
          />

          {/* Password (for update, might be optional) */}
          {/* <TextField
            margin="normal"
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            // placeholder={formik.values.password}
            placeholder="••••••••"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            // helperText={formik.touched.password ? formik.errors.password : ""}
            helperText={
              formik.touched.password &&
              typeof formik.errors.password === "string"
                ? formik.errors.password
                : undefined
            }
            sx={{ flex: "1 0 45%" }}
          /> */}

          <TextField
            margin="normal"
            fullWidth
            id="password"
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"} // التحكم في إظهار/إخفاء كلمة المرور
            placeholder="••••••••"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              formik.touched.password &&
              typeof formik.errors.password === "string"
                ? formik.errors.password
                : undefined
            }
            sx={{ flex: "1 0 45%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Role */}
          <TextField
            select
            margin="normal"
            fullWidth
            id="role"
            label="Role"
            name="role"
            value={formik.values.role}
            onChange={formik.handleChange}
            error={formik.touched.role && Boolean(formik.errors.role)}
            // helperText={formik.touched.role ? formik.errors.role : ""}
            helperText={
              formik.touched.role && typeof formik.errors.role === "string"
                ? formik.errors.role
                : undefined
            }
            sx={{ flex: "1 0 45%" }}
          >
            <MenuItem value={roles[0]}>Admin</MenuItem>
            <MenuItem value={roles[1]}>User</MenuItem>
            {/* {roles.map((role) => (
              <MenuItem key={role} value={role}>
                {(role)}
              </MenuItem>
            ))} */}
          </TextField>

          {/* <FormControl fullWidth margin="normal" sx={{ flex: "1 0 45%" }}>
            <InputLabel htmlFor="user-image">User Image</InputLabel>
            <input
              id="user-image"
              name="profilePic"
              type="file"
              // value={formik.values.profilePic}
              onChange={handleFileChange}
            />
          </FormControl> */}

          {/* <FormControl fullWidth margin="normal" sx={{ flex: "1 0 45%" }}>
            <input
              accept="image/*" // للسماح بملفات الصور فقط
              id="user-image"
              name="profilePic"
              type="file"
              style={{ display: "none" }} // إخفاء عنصر الإدخال
              onChange={handleFileChange}
            />
            <label htmlFor="user-image">
              <Button
                component="span" // يسمح بإستخدام الزر كعنصر label
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                User Image
              </Button>
            </label>
          </FormControl> */}

          {/* <Switch
  checked={formik.values.isActive}
  onChange={(event) => {
    // Directly set the value to a proper Boolean before submitting.
    formik.setFieldValue("isActive", event.target.checked);
  }}
  name="isActive"
/> */}

          <FormControlLabel
            control={
              <Switch
                checked={formik.values.isActive}
                onChange={(event) => {
                  formik.setFieldValue(
                    "isActive",
                    event.target.checked ? true : false
                  );
                }}
                name="isActive"
              />
            }
            label="Active User"
          />

          {/* 
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="access-warehouses">
              Access Warehouses
            </InputLabel>
            <Select
              labelId="access-warehouses"
              id="access-warehouses"
              multiple
              value={formik.values.accessWarehouses}
              onChange={handleWarehouseAccessChange}
              renderValue={(selected) => {
                // Check if all warehouses are selected
                if (selected.length === warehouses.length) {
                  return "All Warehouses";
                } else {
                  // Map through selected values and find the corresponding warehouse names
                  return selected
                    .map((id) => {
                      const warehouse = warehouses.find((wh) => wh.id === id);
                      return warehouse ? warehouse.name : "Unknown"; // Replace 'Unknown' with appropriate fallback
                    })
                    .join(", ");
                }
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem value="all">All Warehouses</MenuItem>
              {warehouses.map((warehouse) => (
                <MenuItem key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <FormControl fullWidth>
            <InputLabel id="demo-multiple-checkbox-label">
              Access Warehouses
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              label="Access Warehouses"
              multiple
              value={personName}
              onChange={handleChange}
              // input={<OutlinedInput label="Access Warehouses" />}
              // renderValue={(selected) => selected.join(", ")}
              renderValue={(selected) => {
                if (selected.length === warehouseNames.length) {
                  return "All Warehouses";
                }
                return `${selected.length} selected`;
              }}
              MenuProps={MenuProps}
            >
              <MenuItem value="all">
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
                <ListItemText primary="Select All" />
              </MenuItem>
              {warehouses.map((warehouse) => (
                <MenuItem key={warehouse.id} value={warehouse.name}>
                  <Checkbox checked={personName.includes(warehouse.name)} />
                  <ListItemText primary={warehouse.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* <Button
  type="submit"
  fullWidth
  variant="contained"
  sx={{ mt: 3, mb: 2 }}
  disabled={formik.isSubmitting || !formik.isValid}
>
  Update User
</Button> */}

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
            color: (theme) => theme.palette.custom?.buttonColor,
            mt: 3,
            mb: 2,
          }}
          disabled={formik.isSubmitting}
        >
          Update User
        </Button>
      </Box>
    </Container>
  );
}
