import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import validationSchema from "../validationSchema";
// import { FormDataHelper } from "../FormDataHelper";
import FormFields from "../FormFields";
import FetchData from "Components/Axios/FetchData";
import useSendData from "Components/Axios/SendData";
import Loading from "Components/ErrorAndLoading/Loading";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";

type AddSupplyProps = {
  handleClose: () => void;
  onAddSupply: any;
};

export default function AddEquipment({
  handleClose,
  onAddSupply,
}: // open,
AddSupplyProps) {
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();
  const {
    data: categoriesData,
    error: fetchError,
    isLoading,
  } = FetchData("categories");
  const { data: tagsData } = FetchData("tags");
  const [tags, setTags] = useState([]);
  const { data: warehouseData } = FetchData("warehouses");
  const [warehouses, setWarehouses] = useState([]);

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);
  const [images, setImages] = useState<File[]>([]);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Handling Categories Data
    if (categoriesData && Array.isArray(categoriesData)) {
      setCategories(categoriesData);
    } else if (categoriesData) {
      toast.error("Error fetching categories.");
    }

    // Handling Tags Data
    if (tagsData && Array.isArray(tagsData)) {
      setTags(tagsData);
    } else if (tagsData) {
      toast.error("Error fetching tags.");
    }
    if (warehouseData && Array.isArray(warehouseData)) {
      setWarehouses(warehouseData);
    } else if (warehouseData) {
      toast.error("Error fetching warehouses.");
    }
  }, [categoriesData, tagsData, warehouseData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      quantity: "",
      price: "",
      minValue: "",
      images: [],
      category: [],
      tags: [],
      warehouse: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("quantity", values.quantity.toString());
      formData.append("price", values.price.toString());
      formData.append("minValue", values.minValue.toString());

      const filteredCategories = selectedCategories.filter(
        (category) => category !== undefined && category !== null
      );
      if (filteredCategories.length > 0) {
        formData.append("categories", JSON.stringify(filteredCategories));
      }

      const filteredTags = selectedTags.filter(
        (tag) => tag !== undefined && tag !== null
      );
      if (filteredTags.length > 0) {
        formData.append("tags", JSON.stringify(filteredTags));
      }

      // console.log("Selected Warehouse: ", selectedWarehouse);
      // const filteredWarehouses = selectedWarehouse.filter(
      //   (warehouse) => warehouse !== undefined && warehouse !== null
      // );
      // if (filteredWarehouses.length > 0) {
      //   formData.append("warehouse", JSON.stringify(filteredWarehouses));
      // } else {
      //   formData.append("warehouse", JSON.stringify(["defaultWarehouse"]));
      // }

      const warehouseValues = Array.isArray(formik.values.warehouse)
        ? formik.values.warehouse.map((warehouse) => parseInt(warehouse, 10))
        : [parseInt(formik.values.warehouse, 10)];

      if (warehouseValues.length > 0) {
        formData.append("warehouse", JSON.stringify(warehouseValues));
      } else {
        formData.append("warehouse", JSON.stringify([1]));
      }

      images
        .filter((image) => image !== undefined && image !== null)
        .forEach((image) => {
          formData.append("images", image);
        });

      // Log all FormData entries
      // for (let pair of formData.entries()) {
      //   console.log(pair[0] + ": " + pair[1]);
      // }

      try {
        const submittedData = await sendData({
          endpoint: "equipments",
          data: formData,
          method: "post",
        });

        if (submittedData) {
          onAddSupply(submittedData.equipment);
          handleClose();
        }
      } catch (error) {
        console.error("Error sending data:", error);
      }
    },
  });

  const handleImagesChange = useCallback(
    (newImages: File[]) => {
      setImages(newImages);
      formik.setFieldValue("images", newImages);
    },
    [formik]
  );

  const handleDropdownChange = (setterFunction: any) => (newValue: any) => {
    setterFunction(newValue);
  };

  const handleDropdownChangeCategory = handleDropdownChange(
    setSelectedCategories
  );
  const handleDropdownChangeTag = handleDropdownChange(setSelectedTags);
  const handleDropdownChangeWarehouse =
    handleDropdownChange(setSelectedWarehouse);

  const errorToDisplay = fetchError || sendError;
  return (
    <>
      <>
        <ErrorDisplay error={errorToDisplay} />
        <Loading isLoading={isLoading || sendLoading} />
      </>
      <FormFields
        formTitle={"Add New equipment"}
        saveButtonLabel={"Create equipment"}
        formik={formik}
        selectedCategories={selectedCategories}
        handleDropdownChangeCategory={handleDropdownChangeCategory}
        selectedTags={selectedTags}
        handleDropdownChangeTag={handleDropdownChangeTag}
        handleDropdownChangeWarehouse={handleDropdownChangeWarehouse}
        categories={categories}
        tags={tags}
        warehouses={warehouses}
        images={images}
        handleImagesChange={handleImagesChange}
        isSubmitting={formik.isSubmitting}
        setSelectedCategories={setSelectedCategories}
        setSelectedTags={setSelectedTags}
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={setSelectedWarehouse}
        setImages={setImages}
        handleClose={handleClose}
      />
    </>
  );
}
