// import useSendData from "Components/Axios/SendData";
// import { AllowToNumberOnly } from "Components/AllowToNumberOnly/AllowToNumberOnly";
import CustomTextField from "Components/CustomTextField/CustomTextField";
import ReusableDialog from "Components/Dialog/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
// import { useFormikContext } from "formik";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";

// import { DataGrid } from "@mui/x-data-grid";
// import { DataGridPro } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { NumericFormat } from "react-number-format";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import axios from "axios";
import { baseUrl } from "utils/baseUrl";
import { AllowToNumberOnly } from "Components/AllowToNumberOnly/AllowToNumberOnly";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
export interface Types {
  formik: any;
  isSubmitting: boolean;
  handleClose: () => void;
  formTitle: string;
  saveButtonLabel: string;
  isEdit?: any;
  open: any;
}

// declare module "@mui/x-data-grid-pro" {
//   interface DataGridProProps {
//     onCellEditCommit?: (params: any, event?: React.SyntheticEvent) => void;
//   }
// }

const FormFields: React.FC<Types> = ({
  formik,
  isSubmitting,
  handleClose,
  formTitle,
  saveButtonLabel,
  isEdit,
  open,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const { sendData, error, isLoading: sendLoading } = useSendData();
  // const [isImagesSelected, setIsImagesSelected] = useState(false);

  // const handleNumberChange = (fieldName: string, decimal: boolean = false) =>
  //   AllowToNumberOnly(
  //     (name: string, value: any) => {
  //       formik.setFieldValue(name, value);
  //     },
  //     fieldName,
  //     decimal
  //   );
  const { values, setFieldValue } = formik;
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [warehouses, setWarehouses] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchResults, setSearchResults] = useState<any[]>([]);
  // const [orderItems, setOrderItems] = useState<any[]>(values.orderItems || []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);
  const [supplierSearchResults, setSupplierSearchResults] = useState<any[]>([]);
  const [equipmentSearchResults, setEquipmentSearchResults] = useState<any[]>(
    []
  );
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("userToken");
      try {
        const response = await axios.get(`${baseUrl}/vendors`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // console.log("Vendors response:", response.data);

        const responseWarehouses = await axios.get(`${baseUrl}/warehouses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // console.log(responseWarehouses.data);

        // console.log("=========+===========================");
        // console.log(response.data);
        // console.log("====================================");

        // setSuppliers(response.data.vendor || []);
        if (response.data && Array.isArray(response.data)) {
          setSuppliers(response.data);
        } else {
          setSuppliers([]);
        }

        setWarehouses(
          Array.isArray(responseWarehouses.data) ? responseWarehouses.data : []
        );
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          // If it's not an Error instance or you don't know the type, you can set a default error message
          setError("An error occurred while fetching the transfers.");
        }
      }
    };

    // console.log("Fetching data due to shouldRefetch change:", shouldRefetch);
    fetchData();
  }, []);

  const handleWarehouseChange = (event: any) => {
    const warehouseId = event.target.value;
    setSelectedWarehouse(warehouseId);
    setFieldValue("warehouse", warehouseId);
    setSupplierSearchResults([]);
    setEquipmentSearchResults([]);
  };

  const handleSearch = async (event: any) => {
    const searchQuery = event.target.value;

    if (searchQuery && searchQuery.length > 0) {
      try {
        const token = localStorage.getItem("userToken");

        const supplierResponse = await axios.get(`${baseUrl}/supplies`, {
          headers: { Authorization: `Bearer ${token}` },
          // params: { keyword: searchQuery },
          params: { search: searchQuery, warehouseId: selectedWarehouse },
        });

        // console.log('==============supplierResponse======================');
        // console.log(supplierResponse.data.supplies);
        // console.log('====================================');

        const equipmentResponse = await axios.get(`${baseUrl}/equipments`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { search: searchQuery, warehouseId: selectedWarehouse },
        });
        // console.log("==============supplierResponse======================");
        // console.log(equipmentResponse.data);
        // console.log("====================================");

        setSupplierSearchResults(supplierResponse.data.supplies || []);
        setEquipmentSearchResults(equipmentResponse.data.equipments || []);
      } catch (error) {
        console.error("Error fetching search results:", error);
        setError("Error fetching search results: " + error);
      }
    } else {
      // setSearchResults([]);
      setSupplierSearchResults([]);
      setEquipmentSearchResults([]);
    }
  };

  const combinedSearchResults = [
    ...(Array.isArray(supplierSearchResults)
      ? supplierSearchResults.map((s) => ({ ...s, type: "Supplier" }))
      : []),
    ...(Array.isArray(equipmentSearchResults)
      ? equipmentSearchResults.map((e) => ({ ...e, type: "Equipment" }))
      : []),
  ];

  const handleSelectSearchResult = (result: any) => {
    if (result) {
      const newOrderItem = {
        id: result.id,
        name: result.name,
        stock: result.quantity,
        // total: result.,
        quantity: 1,
        price: 0,
        total: 0,
        // warehouseName: result.warehouse[0].name,
        warehouseName: result.warehouse ? result.warehouse.name : "N/A",
      };
      setFieldValue("product", [...values.product, newOrderItem]);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const QuantityCell = (params: any) => {
    const [value, setValue] = React.useState(params.value);
    const { id, field } = params;

    const handleChange = (event: any) => {
      const newValue = event.target.value;
      setValue(newValue);
      // Update the formik state
      const updatedProduct = formik.values.product.map((item: any) => {
        if (item.id === id) {
          return { ...item, [field]: newValue };
        }
        return item;
      });
      setFieldValue("product", updatedProduct);
    };

    return <TextField value={value} onChange={handleChange} type="number" />;
  };

  const handleNumberChange = (fieldName: string, decimal: boolean = false) =>
    AllowToNumberOnly(
      (name: string, value: any) => {
        formik.setFieldValue(name, value);
      },
      fieldName,
      decimal
    );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const PriceCell = (params: any) => {
    return (
      <CustomTextField
        name="price"
        label="Price"
        // type="number"
        value={formik.values.price}
        // onChange={formik.handleChange}
        onChange={handleNumberChange("price", true)}
        error={formik.touched.price && Boolean(formik.errors.price)}
        helperText={formik.touched.price && formik.errors.price}
      />
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const NumberInputCell1: any = (params: any) => {
    // If params.value can be either string or number, you need to assert the type.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const value = params.value as number;

    return (
      <NumericFormat value={12323} customInput={TextField} />
      // <NumericFormat
      //   value={1}
      //   onValueChange={(values) => {
      //     const { floatValue } = values;
      //     params.api.setEditCellValue({
      //       id: params.id,
      //       field: params.field,
      //       value: floatValue,
      //     });
      //   }}
      //   thousandSeparator
      //   // isNumericString
      //   customInput={TextField}
      //   // type=""
      //   decimalScale={3}
      //   prefix={"$"}
      //   fullWidth
      // />
    );
  };
  // const renderQuantityCell = (params: any) => {
  //   const [value, setValue] = useState(params.value);

  //   const handleChange = (event: any) => {
  //     setValue(event.target.value);
  //     // Here you can call formik.setFieldValue or any other state management logic to update the quantity
  //     const updatedProduct = formik.values.product.map((item: any) => {
  //       if (item.id === params.id) {
  //         return { ...item, quantity: event.target.value };
  //       }
  //       return item;
  //     });
  //     setFieldValue("product", updatedProduct);
  //   };

  //   return <TextField value={value} onChange={handleChange} type="number" />;
  // };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isNumeric = (value: any) => {
    return !isNaN(value) && !isNaN(parseFloat(value));
  };

  const NumberFormatCell = (params: any) => {
    const { id, value, api, field } = params;
    const handleChange = (event: any) => {
      const newValue = event.target.value;
      if (!isNaN(newValue) && newValue.trim() !== "") {
        // تحديث قيمة الخلية
        api.setEditCellValue({ id, field, value: Number(newValue) });
      }
    };

    return (
      <TextField
        type="number"
        value={value}
        onChange={handleChange}
        inputProps={{
          min: 0,
          step: "0.01",
        }}
        fullWidth
      />
    );
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const validatePrice = (value: any) => {
    return !isNaN(value) && value !== ""; // Returns true if value is a number and not an empty string
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const columns = [
    { field: "name", headerName: "Product", width: 100 },
    { field: "stock", headerName: "Stock", width: 60 },
    {
      field: "quantity",
      headerName: "Qty",
      width: 50,
      // type: "number",
      editable: true,
      // renderCell: (params: any) => <QuantityCell {...params} />,
    },
    {
      field: "price",
      headerName: "Price Per",
      width: 100,
      // type: "number",
      editable: true,
      // renderCell: (params: any) => (
      //   <NumberFormatCell
      //     id={params.id}
      //     value={params.value}
      //     column={params.column}
      //   />
      // ),
      renderCell: NumberFormatCell,

      // renderCell: PriceCell,
    },
    {
      field: "total",
      headerName: "Total",
      width: 70,
      // valueGetter: (params: any) => params.row.price * params.row.quantity,
      valueGetter: (params: any) => {
        const price = params.row.price || 0;
        const quantity = params.row.quantity || 0;
        // Check if both price and quantity are not zero
        if (price !== 0 && quantity !== 0) {
          return price * quantity;
        }
        return ""; // Return empty string if either is zero
      },
    },
    { field: "warehouse", headerName: "Warehouse", width: 110 },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 57,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        const onClick = () => {
          const idToDelete = params.id;
          // Use Formik's setFieldValue to update the product array by filtering out the item to delete
          setFieldValue(
            "product",
            values.product.filter((item: any) => item.id !== idToDelete)
          );
        };

        return (
          <IconButton onClick={onClick}>
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  const handleDelete = (id: any) => {
    setFieldValue(
      "product",
      values.product.filter((item: any) => item.id !== id)
    );
  };

  const handleSubmit = () => {
    setConfirmOpen(true);
  };

  const handleConfirm = () => {
    setConfirmOpen(false);
    formik.submitForm();
  };

  // console.log("=============supplier=======================");
  // console.log(suppliers);
  // console.log("====================================");

  return (
    <>
      <ReusableDialog
        formTitle={formTitle}
        formik={formik}
        isSubmitting={isSubmitting}
        saveButtonLabel={saveButtonLabel}
        isEdit={isEdit}
        onClose={handleClose}
        open={open}
        dialogMaxWidth="md"
        handleSubmit={handleSubmit}
        showButton={false}

        // onClose={handleCloseDialog}
      >
        <>
          {/* <CustomTextField
            name="companyName"
            label="Company Name"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={formik.touched.companyName && formik.errors.companyName}
          /> */}
          {/* <DatePicker
              label="Date"
              value={formik.values.date}
              onChange={(date: any) => setFieldValue("date", date)}
              renderInput={(params: any) => <TextField {...params} />}
            /> */}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: "15px",
            }}
          >
            {/* <CustomTextField
              name="date"
              label="Date"
              type="date"
              value={formik.values.date}
              onChange={formik.handleChange}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
            
            /> */}

            <TextField
              fullWidth
              margin="normal"
              name="date"
              label="Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              // value={formik.values.date}
              // onChange={formik.handleChange}
              value={formik.values.date} // This will now be controlled by Formik
              onChange={formik.handleChange}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel id="supplier-label">Supplier</InputLabel>
              <Select
                labelId="supplier-label"
                id="supplier-select"
                value={formik.values.vendor}
                label="Supplier"
                onChange={(event) =>
                  setFieldValue("vendor", event.target.value)
                }
              >
                {suppliers &&
                  suppliers.map((supplier) => (
                    <MenuItem key={supplier.id} value={supplier.id}>
                      {supplier.vendorName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: "15px",
            }}
          >
            <FormControl fullWidth margin="normal">
              <InputLabel id="warehouse-label">Warehouse</InputLabel>
              <Select
                labelId="warehouse-label"
                id="warehouse-select"
                value={formik.values.warehouse}
                label="Warehouse"
                // onChange={(event) =>
                //   setFieldValue("warehouse", event.target.value)
                // }
                onChange={handleWarehouseChange}
              >
                {warehouses.map((warehouse) => (
                  <MenuItem key={warehouse.id} value={warehouse.id}>
                    {warehouse.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Autocomplete
              fullWidth
              options={combinedSearchResults}
              getOptionLabel={(option: any) => option.name || ""}
              // onInputChange={(event, newInputValue) => {
              //   handleSearch(event);
              // }}
              onInputChange={(event, newInputValue) => {
                handleSearch({ target: { value: newInputValue } });
              }}
              // onInputChange={(event, newInputValue) => {
              //   handleSearch({ target: { value: newInputValue } });
              // }}
              onChange={(event: any, newValue: any) =>
                handleSelectSearchResult(newValue)
              }
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Scan/Search Product by Name"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Box>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 580 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Stock</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Price Per</TableCell>
                  <TableCell
                  // align="right"
                  >
                    Total
                  </TableCell>
                  <TableCell>Warehouse</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.product.map((row: any, index: any) => {
                  const handleQuantityChange = (event: any, index: number) => {
                    const quantity = parseFloat(event.target.value);
                    if (!isNaN(quantity)) {
                      const updatedProducts = [...values.product];
                      const price =
                        parseFloat(updatedProducts[index].price) || 0;
                      updatedProducts[index] = {
                        ...updatedProducts[index],
                        quantity: quantity,
                        total: price * quantity,
                      };
                      setFieldValue("product", updatedProducts);
                    }
                  };

                  const handlePriceChange = (event: any, index: number) => {
                    const price = parseFloat(event.target.value);
                    if (!isNaN(price)) {
                      const updatedProducts = [...values.product];
                      const quantity =
                        parseFloat(updatedProducts[index].quantity) || 0;
                      updatedProducts[index] = {
                        ...updatedProducts[index],
                        price: price,
                        total: price * quantity,
                      };
                      setFieldValue("product", updatedProducts);
                    }
                  };

                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>
                        {isEdit ? row.id.quantity : row.stock}
                      </TableCell>
                      <TableCell sx={{ width: 140 }}>
                        {/* <TextField type="number" value={row.quantity} /> */}
                        <NumericFormat
                          value={row.quantity}
                          // onValueChange={(values) => {
                          //   const { floatValue } = values;
                          //   params.api.setEditCellValue({
                          //     id: params.id,
                          //     field: params.field,
                          //     value: floatValue,
                          //   });
                          // }}
                          onValueChange={(values) => {
                            const { floatValue } = values;
                            handleQuantityChange(
                              { target: { value: floatValue } },
                              index
                            );
                          }}
                          thousandSeparator
                          // isNumericString
                          customInput={TextField}
                          // type=""
                          decimalScale={3}
                          // prefix={"$"}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell sx={{ width: 140 }}>
                        <NumericFormat
                          value={row.price}
                          // onChange={handlePriceChange}
                          onValueChange={(values) => {
                            const { floatValue } = values;
                            handlePriceChange(
                              { target: { value: floatValue } },
                              index
                            );
                          }}
                          // onValueChange={(values) => {
                          //   const { floatValue } = values;
                          //   params.api.setEditCellValue({
                          //     id: params.id,
                          //     field: params.field,
                          //     value: floatValue,
                          //   });
                          // }}
                          thousandSeparator
                          // isNumericString
                          customInput={TextField}
                          // type=""
                          decimalScale={3}
                          prefix={"$"}
                          fullWidth
                        />
                      </TableCell>
                      {/* <TableCell>$ {row.quantity * row.price}</TableCell> */}
                      <TableCell>
                        <NumericFormat
                          value={row.quantity * row.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value) => <>{value}</>}
                        />
                      </TableCell>

                      <TableCell>{row.warehouseName}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleDelete(row.id)}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* <div
            style={{
              height: 400,
              // minHeight:  '100px',
              width: "100%",
            }}
          >
            <DataGrid
              rows={formik.values.product}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              hideFooterSelectedRowCount
              getRowId={(row) => row.id}
              processRowUpdate={(newRow, oldRow) => {
                const price = parseFloat(newRow.price) || 0;
                const quantity = parseInt(newRow.quantity, 10) || 0;
                newRow.total = price * quantity;

                const updatedRows = formik.values.product.map((item: any) => {
                  if (item.id === oldRow.id) {
                    return { ...item, ...newRow, total: newRow.total };
                  }
                  return item;
                });
                setFieldValue("product", updatedRows);

                return newRow;
              }}
            />
          </div> */}

          <CustomTextField
            name="notes"
            label="Notes"
            multiline
            rows={3}
            value={formik.values.notes}
            onChange={formik.handleChange}
            error={formik.touched.notes && Boolean(formik.errors.notes)}
            helperText={formik.touched.notes && formik.errors.notes}
          />
        </>
      </ReusableDialog>

      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Purchase
          <IconButton
            aria-label="close"
            onClick={() => setConfirmOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to create this purchase with the following
            details?
          </DialogContentText>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>Date:</strong> {formik.values.date}
            </Typography>
            <Typography variant="body1">
              <strong>Supplier:</strong>{" "}
              {suppliers.find((s) => s.id === formik.values.vendor)?.vendorName}
            </Typography>
            <Typography variant="body1">
              <strong>Warehouse:</strong>{" "}
              {warehouses.find((w) => w.id === formik.values.warehouse)?.name}
            </Typography>
            <Typography variant="body1">
              <strong>Notes:</strong> {formik.values.notes}
            </Typography>
            <Typography variant="body1">
              <strong>Products:</strong>
            </Typography>
            <Box ml={2}>
              {formik.values.product.map((product: any, index: any) => (
                <Typography key={index} variant="body2">
                  {product.name} - Quantity: {product.quantity} - Price: $
                  {product.price} - Total: ${product.total}
                </Typography>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            No
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormFields;
