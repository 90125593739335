import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  IconButton,
  Box,
  Divider,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slider from "react-slick";
import FetchData from "Components/Axios/FetchData";
import TagIcon from "@mui/icons-material/LocalOffer";
interface Category {
  id: string;
  name: string;
}

interface Tag {
  id: string;
  name: string;
}

interface Supply {
  id: string;
  description: string;
  quantity: number;
  price: number;
  minValue: number;
  images: any;
  category: Category[];
  tag: Tag[];
  name: string;
  updatedAt?: string;
}

type SupplyDetailsProps = {
  handleClose: () => void;
  open: boolean;
  supply: Supply;
};

const SupplyDetails: React.FC<SupplyDetailsProps> = ({
  supply,
  open,
  handleClose,
}) => {
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  const item_name = supply;
  const {
    data: fetchedData,
    error: fetchError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading,
  } = FetchData(`supplies/${supply.id}`);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [supplies, setSupplies] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (fetchedData) {
      // console.log("====================================");
      // console.log(fetchedData);
      // console.log("====================================");
      setSupplies(fetchedData.item_name);
      setQrCodeUrl(fetchedData.supply.qrcode);
    } else if (fetchError) {
      setError(`Error loading ${item_name} `);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedData, fetchError]);
  if (isLoading) return <div>Loading...</div>;
  if (fetchError) return <div>Error loading data</div>;
  if (!fetchedData || !fetchedData.supply) return <div>No data available</div>;

  const equipmentData = fetchedData.supply;

  const settings = {
    customPaging: function (i: any) {
      return (
        <Box
          sx={
            {
              // mb:5,
              // mt:5,
            }
          }
        >
          <img
            src={item_name.images[i].secure_url}
            style={{ width: "50px", height: "50px" }}
            alt=""
          />
        </Box>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const totalValue = item_name.price * item_name.quantity;

  if (!item_name) return <div>Loading...</div>;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent>
        <IconButton onClick={handleClose} style={{ float: "right" }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom>
          Supply Details
        </Typography>

        <Divider
          sx={{
            borderBottomWidth: 2,
            marginBottom: 2,
            marginTop: 1,
            width: "100% !important",
          }}
        />

        <Grid container spacing={3} p="15px">
          {/* Image Slider */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              Basic Info
            </Typography>
            {item_name.images && item_name.images.length > 0 ? (
              <Slider {...settings}>
                {item_name.images.map((img: any, idx: any) => (
                  <div key={idx}>
                    <img
                      src={img.secure_url}
                      alt={`Supply ${idx}`}
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "contain",
                        marginBottom: "40px",
                      }}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <Typography variant="subtitle1">No images available</Typography>
            )}
          </Grid>

          {/* Text and QR Section */}
          <Grid item xs={12} sm={6} sx={{ paddingLeft: "40px !important" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ color: "#d32f2f", fontWeight: "bold" }}
                >
                  Name
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {item_name.name}
                </Typography>

                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#d32f2f" }}
                >
                  Price Per:{" "}
                  <span style={{ color: "black", fontWeight: "400" }}>
                    ${item_name.price}
                  </span>
                </Typography>

                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#d32f2f" }}
                >
                  Stock:{" "}
                  <span style={{ color: "black", fontWeight: "400" }}>
                    {item_name.quantity} Units
                  </span>
                </Typography>

                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#d32f2f" }}
                >
                  Total Price:{" "}
                  <span style={{ color: "black" }}>
                    ${totalValue.toFixed(2)}
                  </span>
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Low Alert:{" "}
                  <span style={{ color: "black" }}>{item_name.minValue}</span>
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Updated At:{" "}
                  <span style={{ color: "black" }}>
                    {new Date(item_name.updatedAt).toLocaleString()}
                  </span>
                </Typography>

                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ color: "#d32f2f", fontWeight: "bold" }}
                >
                  Notes
                </Typography>
                <Typography variant="subtitle1">
                  {item_name.description || "No description provided."}
                </Typography>
              </Box>
              {qrCodeUrl && (
                <img
                  src={qrCodeUrl}
                  alt="QR Code"
                  style={{ width: 80, height: 80 }}
                />
              )}
            </Box>
          </Grid>

          {/* Categories and Warehouse Section */}
          <Divider
            sx={{
              borderBottomWidth: 2,
              // marginBottom: 2,
              marginTop: 5,
              width: "100% !important",
            }}
          />
          <Grid container spacing={3} p="15px">
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                Categories
              </Typography>
              <Typography variant="subtitle1">
                {equipmentData.categories
                  ? equipmentData.categories.map((c: any) => c.name).join(", ")
                  : "No categories available."}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ paddingLeft: "50px !important" }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                Warehouse
              </Typography>
              <Typography variant="subtitle1">
                {equipmentData.warehouse
                  ? equipmentData.warehouse.name
                  : "No warehouse specified."}
              </Typography>
            </Grid>
          </Grid>

          {/* Tags Section */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              Tags
            </Typography>

            {/* Container with border around all tags */}
            <Box
              sx={{
                border: "1px solid #e0e0e0", // Border around all tags
                borderRadius: "8px", // Rounded corners for the border
                padding: "8px", // Inner padding to provide space inside the box
                display: "flex", // Flexbox layout
                flexWrap: "wrap", // Wrap tags if they overflow
                gap: 1, // Space between each tag
                // backgroundColor: "#fafafa", // Optional: Light background color
              }}
            >
              {equipmentData.tags && equipmentData.tags.length > 0 ? (
                equipmentData.tags.map((tag: any, idx: any) => (
                  <Chip
                    key={idx}
                    icon={<TagIcon style={{ color: "#d32f2f" }} />}
                    label={tag.name}
                    sx={{
                      backgroundColor: "#f1f3f5", // Light background
                      color: "#5f6368", // Text color
                      borderRadius: "20px", // Rounded corners for each tag
                      padding: "2px 8px", // Padding inside each tag
                      border: "1px solid #d1d5db", // Border around each tag
                    }}
                  />
                ))
              ) : (
                <Typography>No tags available.</Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default SupplyDetails;
