import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
} from "@mui/material";

interface DeleteWarehouseProps {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  warehouseName: string;
}

const DeleteUser: React.FC<DeleteWarehouseProps> = ({
  show,
  handleClose,
  handleConfirm,
  warehouseName,
}) => {
  return (
    <Dialog open={show} onClose={handleClose}>
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
          color: (theme) => theme.palette.custom?.dialogColor,
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the user '{warehouseName}'?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="info">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DeleteUser;
