// import Dropdown from "Components/Dropdown/Dropdown";
import useSendData from "Components/Axios/SendData";
// import { AllowToNumberOnly } from "Components/AllowToNumberOnly/AllowToNumberOnly";
import CustomTextField from "Components/CustomTextField/CustomTextField";
import ReusableDialog from "Components/Dialog/Dialog";

export interface Types {
  formik: any;
  isSubmitting: boolean;
  handleClose: () => void;
  formTitle: string;
  saveButtonLabel: string;
  isEdit?: any;
  open: boolean;
}

const FormFields: React.FC<Types> = ({
  formik,
  isSubmitting,
  handleClose,
  formTitle,
  saveButtonLabel,
  isEdit,
  open,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sendData, error, isLoading: sendLoading } = useSendData();
  // const [isImagesSelected, setIsImagesSelected] = useState(false);

  // const handleNumberChange = (fieldName: string, decimal: boolean = false) =>
  //   AllowToNumberOnly(
  //     (name: string, value: any) => {
  //       formik.setFieldValue(name, value);
  //     },
  //     fieldName,
  //     decimal
  //   );

  return (
    <>
      <ReusableDialog
        formTitle={formTitle}
        formik={formik}
        isSubmitting={isSubmitting}
        saveButtonLabel={saveButtonLabel}
        isEdit={isEdit}
        onClose={handleClose}
        open={open}
        // onClose={handleCloseDialog}
      >
        <>
          {/* Name field */}
          <CustomTextField
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          {/* Description field */}
          {/* <CustomTextField
                name="description"
                label="Description"
                multiline
                rows={4}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              /> */}
        </>
      </ReusableDialog>
    </>
  );
};

export default FormFields;
