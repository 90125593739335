import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  firstName: string | null;
  id?: string | null;
}

const initialState: AuthState = {
  firstName: null,
  id: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ firstName: string }>) => {
      state.firstName = action.payload.firstName;
    },
    setUserId: (state, action: PayloadAction<{ id: string }>) => {
      state.id = action.payload.id;
    },
    logoutUser: (state) => {
      state.firstName = null;
      state.id = null;
    },
  },
});

export const { setUser, setUserId, logoutUser } = authSlice.actions;

export default authSlice.reducer;
