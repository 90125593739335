import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "./Loading.scss";
const brandLogo = require("./../../assets/logo.png");

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backdropFilter: "blur(50px)",
    // zIndex: 9999999999999999999999,

    // backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  logo: {
    width: "100px",
    height: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
  },
  // blurredBackground: {
  //   // position: "fixed",
  //   backdropFilter: "blur(9000px)",
  //   zIndex: 99999999999999999999999999999999999,
  //   width: "100%",
  //   // height: "100%",
  //   // background: "rgba(0, 0, 0, 0.5)",
  // },
}));

type LoadingIndicatorProps = {
  isLoading: boolean;
};

const Loading: React.FC<LoadingIndicatorProps> = ({ isLoading }) => {
  const classes = useStyles();

  if (!isLoading) return null;

  return (
    <>
      {/* <CircularProgress size={24} className={classes.circularProgress} /> */}
      <div className={classes.backdrop}>
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems="center"
          className="spanerContainer"
        >
          <div>
            <div className="center">
              <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <img src={brandLogo} alt="Brand Logo" className={classes.logo} />
            </div>
            <Typography sx={{ textAlign: "center" }}>Please Wait...</Typography>
          </div>
        </Box>
      </div>
    </>
  );
};

export default Loading;
