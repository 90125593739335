import Login from "Components/Login/Login";
import ProtectedRoutes, {
  RedirectIfLoggedIn,
} from "Components/ProtectedRoutes/ProtectedRoutes";
import Dashboard from "pages/Dashboard/Dashboard";
import Test from "pages/Test/Test";
import MainLayout from "pages/layouts/MainLayout";
import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Warehouse from "pages/Warehouses/Warehouse";
import UsersManagement from "pages/UsersManagement/UsersManagement";
import Supplies from "pages/Supplies/Supplies";
import Categories from "pages/Categories/Categories";
import Tags from "pages/Tags/Tags";
import Equipment from "pages/Equipment/Equipment";
import CategoryDetails from "pages/Categories/CategoryDetails/CategoryDetails";
import TagDetails from "pages/Tags/TagDetails/TagDetails";
import WarehouseDetails from "pages/Warehouses/WarehouseDetails/WarehouseDetails";
import Transfers from "pages/Transfers/Transfers";
import ForgotPassword from "Components/ForgotPassword/ForgotPassword";
import ResetPassword from "Components/ResetPassword/ResetPassword";
import Purchase from "pages/Purchase/Purchase";
import VendorsGroup from "pages/VendorsGroup/VendorsGroup";
import Vendors from "pages/Vendors/Vendors";
import LoadOrderIn from "pages/LoadOrderIn/LoadOrderIn";

export default function MyRoutes() {
  const [userData, setUserData] = useState<any>(null);

  function saveUser() {
    let encodedToken = localStorage.getItem("userToken");
    let decoded = jwtDecode(encodedToken);
    setUserData(decoded);
  }

  // const getAccessToken = () => {
  //   return localStorage.getItem(ACCESS_TOKEN_KEY);
  // };

  // const getUser = () => {
  //   const token = getAccessToken();
  //   if (!token) {
  //     return null;
  //   }
  //   return getUserFromToken(token);
  // };

  // const navigate = useNavigate();
  // const [user, setUser] = useState();

  // const handleLogin = (user: any) => {
  //   setUser(user);
  //   navigate("/home");
  // };

  // const handleLogout = () => {
  //   setUser(null);
  //   navigate("/");
  // };

  return (
    <>
      <Routes>
        {/* <Route path="/login" element={<Login saveUser={saveUser} />} /> */}
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />
        <Route
          path="/login"
          element={
            <RedirectIfLoggedIn>
              <Login saveUser={saveUser} />
            </RedirectIfLoggedIn>
          }
        />

        <Route
          path="/"
          element={
            <ProtectedRoutes>
              <MainLayout userData={userData} setUserData={setUserData} />
            </ProtectedRoutes>
          }
        >
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoutes>
                <Dashboard />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/warehouses"
            element={
              <ProtectedRoutes>
                <Warehouse />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/warehouse/:id"
            element={
              <ProtectedRoutes>
                <WarehouseDetails />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/usersmanagement"
            element={
              <ProtectedRoutes>
                <UsersManagement />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/supplies"
            element={
              <ProtectedRoutes>
                <Supplies />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/equipment"
            element={
              <ProtectedRoutes>
                <Equipment />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/tags"
            element={
              <ProtectedRoutes>
                <Tags />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/tag/:id"
            element={
              <ProtectedRoutes>
                <TagDetails />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/categories"
            element={
              <ProtectedRoutes>
                <Categories />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/category/:id"
            element={
              <ProtectedRoutes>
                <CategoryDetails />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/transfers"
            element={
              <ProtectedRoutes>
                <Transfers />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/vendorsgroup"
            element={
              <ProtectedRoutes>
                <VendorsGroup />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/vendorsgroup/:id"
            element={
              <ProtectedRoutes>
                <Vendors />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/purchases"
            element={
              <ProtectedRoutes>
                <Purchase />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/loadorderin"
            element={
              <ProtectedRoutes>
                <LoadOrderIn />
              </ProtectedRoutes>
            }
          />

          {/* <Route
            path="/warehouses/new"
            element={
              <ProtectedRoutes>
                <AddWarehouse />
              </ProtectedRoutes>
            }
          /> */}

          {/* <Route path="/clients" element={<Clients />} /> */}
          {/* <Route path="/warehouses/new" element={<AddClient />} /> */}
          {/* <Route path="/test" element={<Test />} /> */}
          {/* <Route path="/test"></Route> */}
          <Route path="/test" element={<Test />} />

          {/* <Route path="/test/:supplyId" element={<Test categories=your categories data />} /> */}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Route>
      </Routes>
    </>
  );
}
