import { useEffect, useState } from "react";

import { Box, IconButton, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
// import { useNavigate } from "react-router-dom";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridRenderCellParams } from "@mui/x-data-grid";
import ConfirmDelete from "Components/Axios/ConfirmDelete";
import { toast } from "react-toastify";

import FetchData from "Components/Axios/FetchData";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import ReusableTable from "Components/Table/Table";
import AddPurchase from "./AddPurchase/AddPurchase";
import UpdatePurchase from "./UpdatePurchase/UpdatePurchase";
import PurchaseDetails from "./PurchaseDetails/PurchaseDetails";

// import DeleteWarehouse from "./DeleteWarehouse/DeleteWarehouse";
// import EditWarehouse from "./EditWarehouse/EditWarehouse";

interface VendorDetails {
  id?: string;
  vendorName?: string;
}

interface types {
  id: string;
  date: string;
  warehouse: WarehouseDetails;
  // toWarehouse: WarehouseDetails;
  // supplies: string[];
  // equipments: string[];
  quantity: number;
  // status: string;
  note: string;
  vendor: VendorDetails;

  name?: string;
}

interface WarehouseDetails {
  id?: string;
  name?: string;
  toLowerCase?: any;
  // include other properties if necessary
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export default function Purchase() {
  const [selectedSupply, setSelectedSupply] = useState<types | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewDetailsOpen, setViewDetailsOpen] = useState(false);

  const [error, setError] = useState<string | null>(null);

  // const [warehouses, setWarehouses] = useState<types[]>([]);
  const [warehouses, setWarehouses] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedWarehouse, setSelectedWarehouse] = useState<types | null>(
    null
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editingWarehouse, setEditingWarehouse] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
  const { data, error: Error, isLoading } = FetchData("purchases");

  useEffect(() => {
    if (data) {
      if (Array.isArray(data)) {
        const purchases = data.map((purchase: any) => {
          return {
            ...purchase,
            date: new Date(purchase.date).toLocaleString("en-US", {
              timeZone: "America/New_York",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
          };
        });

        setWarehouses(purchases);
      } else {
        setError("Data is not in expected format");
      }
    }
  }, [data]);

  const handleOpenDialog = () => {
    setSelectedWarehouse(null);
    setOpenDialog(true);
  };

  // Function to handle closing the Dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedWarehouse(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDeleteClick = (warehouseId: any) => {
    const supply = warehouses.find((wh) => wh.id === warehouseId);

    if (supply) {
      setSelectedSupply(supply);
      setDeleteDialogOpen(true);
    } else {
      toast.error("purchase not found.");
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleEdit = (id: any) => {
    const transferToEdit = warehouses.find((wh) => wh.id === id);
    if (transferToEdit) {
      setEditingWarehouse(transferToEdit);
      setSelectedSupply(transferToEdit); // Add this line to set selectedSupply
      setIsEditModalOpen(true);
    } else {
      toast.error("purchase not found.");
    }
  };

  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
  };

  const columns: GridColDef[] = [
    { field: "date", headerName: "Date", flex: 1 },
    { field: "vendor", headerName: "Vendor", flex: 1 },
    { field: "warehouse", headerName: "Warehouse", flex: 1 },
    // { field: "quantity", headerName: "Quantity", flex: 1 },
    // { field: "status", headerName: "Status", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton onClick={() => handleOpenViewDetails(params.id)}>
            <VisibilityIcon />
          </IconButton>

          {/* <IconButton onClick={() => handleEdit(params.id)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteClick(params.id)}>
            <DeleteIcon />
          </IconButton> */}
        </>
      ),
    },
  ];

  const filteredRows = warehouses
    .filter((warehouse: any) => {
      const searchLower = searchText.toLowerCase();
      return (
        warehouse.date.toLowerCase().includes(searchLower) ||
        warehouse.warehouse?.name?.toLowerCase().includes(searchLower) ||
        warehouse.vendor?.vendorName?.toLowerCase().includes(searchLower)
      );
    })
    .map((warehouse: any) => {
      return {
        id: warehouse.id,
        date: warehouse.date,
        warehouse: warehouse.warehouse?.name || "N/A",
        vendor: warehouse.vendor?.vendorName || "N/A",
      };
    });

  // const onTransferAdded = async () => {
  //   const fetchData = async () => {
  //     const token = localStorage.getItem("userToken");
  //     try {
  //       const response = await axios.get(`${baseUrl}/transfers`, {
  //         headers: {
  //           token: token,
  //         },
  //       });
  //       // console.log("New transfer data:", response.data.transfer);

  //       setWarehouses(response.data.transfer);

  //       // console.log(response.data.transfer);
  //     } catch (error) {
  //       if (error instanceof Error) {
  //         setError(error.message);
  //       } else {
  //         // If it's not an Error instance or you don't know the type, you can set a default error message
  //         setError("An error occurred while fetching the transfers.");
  //       }
  //     }
  //   };

  //   // console.log("Fetching data due to shouldRefetch change:", shouldRefetch);
  //   fetchData();
  // };

  const handleOpenViewDetails = (supplyId: any) => {
    const supply = warehouses.find((s: any) => s.id === supplyId);
    if (supply) {
      setSelectedSupply(supply);
      setViewDetailsOpen(true);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCloseViewDetails = () => {
    setViewDetailsOpen(false);
  };

  // if (error) {
  //   return (
  //     <Box>
  //       <Typography color="error">Error: {error}</Typography>
  //       {/* Add buttons or links to retry or go back to another page */}
  //     </Box>
  //   );
  // }

  return (
    <Box>
      <Typography variant="h4" fontWeight="medium">
        Purchase
      </Typography>

      <>
        <ErrorDisplay error={Error || error} />
        {/* <Loading isLoading={isLoading} /> */}
      </>

      <ReusableTable
        searchText={searchText}
        onSearchChange={handleSearchChange}
        onAddButtonClick={handleOpenDialog}
        rows={filteredRows}
        columns={columns}
        loading={isLoading}
        addButtonName="Add New Purchase"
      />

      {/* <Dialog
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
            color: (theme) => theme.palette.custom?.dialogColor,
          }}
        >
        </Box>
      </Dialog> */}
      <AddPurchase
        open={openDialog}
        handleClose={handleCloseDialog}
        onAddSupply={setWarehouses}
        // onTransferAdded={onTransferAdded}
      />

      {editingWarehouse && (
        <UpdatePurchase
          onUpdateSuccess={setWarehouses}
          selectedCategory={selectedSupply}
          open={isEditModalOpen}
          handleClose={() => setIsEditModalOpen(false)}
        />
      )}

      {selectedSupply && (
        <PurchaseDetails
          purchaseId={selectedSupply.id}
          open={viewDetailsOpen}
          handleClose={handleCloseViewDetails}
        />
      )}

      {deleteDialogOpen && selectedSupply && (
        <ConfirmDelete
          // itemName={selectedSupply.vendor.vendorName}
          itemName={selectedSupply.vendor?.vendorName || "N/A"}
          endpoint={`purchases/${selectedSupply.id}`}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onDeleted={() => {
            // setWarehouses(
            //   warehouses?.filter(
            //     (supply: any) => supply.id !== selectedSupply.id
            //   )
            // );
            setWarehouses((prevDetails) =>
              prevDetails.filter((s: any) => s.id !== selectedSupply.id)
            );
            setDeleteDialogOpen(false);
          }}
        />
      )}
    </Box>
  );
}
