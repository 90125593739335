import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface OptionType {
  id: string;
  name: string;
}

interface DropdownProps {
  label: string;
  id: string;
  value: string[];
  onChange: any;
  options: OptionType[];
  renderValue?: (selected: string[]) => string;
  // MenuProps: object;
  multiple?: boolean;
  showSelectAll?: boolean;
}

// const isAllSelected = (selected: string[], options: OptionType[]) =>
//   selected.length === options.length;

const Dropdown: React.FC<DropdownProps> = ({
  label,
  id,
  value,
  options,
  onChange,
  multiple = false,
  showSelectAll = true,
  // renderValue,
}) => {
  // const handleSelectionChange = (event: SelectChangeEvent<string[]>) => {
  //   const {
  //     target: { value: newValue },
  //   } = event;

  //   if (newValue.includes("all")) {
  //     onChange(event);
  //   } else {
  //     onChange(event);
  //   }
  // };

  // const handleSelectionChange = (event: SelectChangeEvent<string[]>) => {
  //   const { value: newValue } = event.target as any;
  //   if (newValue.includes("all")) {
  //     const newSelected =
  //       newValue.includes("all") && value.length !== options.length
  //         ? options.map((option) => option.id)
  //         : [];
  //     onChange(newSelected);
  //   } else {
  //     onChange(newValue);
  //   }
  // };
  //------------------- الكود الاصلي ----------------------------------------------
  // const handleSelectionChange = (event: SelectChangeEvent<string[]>) => {
  //   const { target } = event;
  //   const newValue = target.value as string[]; // Assert that value is an array of strings

  //   // Rest of your logic...
  //   if (newValue.includes("all")) {
  //     onChange(
  //       newValue.length === options.length + 1
  //         ? []
  //         : options.map((option) => option.id)
  //     );
  //   } else {
  //     onChange(newValue.filter((v) => v !== "all")); // TypeScript should not complain here
  //   }
  // };
  //---------------------------------------------------------

  const handleSelectionChange = (event: SelectChangeEvent<any>) => {
    if (event?.target) {
      const newValue = event.target.value;

      if (newValue.includes("all")) {
        const allOptionsSelected = newValue.length === options.length + 1;
        const newSelected = allOptionsSelected
          ? []
          : options.map((option) => option.id);
        onChange(newSelected);
      } else {
        const filteredNewValue = newValue.filter((v: string) => v !== "all");
        onChange(filteredNewValue);
      }
    }
  };

  const renderSelectedValue = (selected: string[]) => {
    if (selected.length === options.length) {
      return "All Selected";
    } else {
      return `${selected.length} selected`;
    }
  };

  // let showSelectAll = true;

  return (
    <FormControl fullWidth margin="dense">
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        MenuProps={MenuProps}
        multiple={multiple}
        value={value}
        onChange={handleSelectionChange}
        input={<OutlinedInput label={label} />}
        // renderValue={renderValue}
        renderValue={renderSelectedValue}
      >
        {showSelectAll ? (
          <MenuItem value="all">
            <Checkbox checked={value.length === options.length} />
            <ListItemText primary="Select All" />
          </MenuItem>
        ) : (
          ""
        )}

        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox checked={value.indexOf(option.id) > -1} />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
