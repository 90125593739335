import React from "react";
import { Box, Card, TextField, Typography } from "@mui/material";
import ButtonAdd from "Components/ButtonAdd/ButtonAdd";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Loading from "Components/ErrorAndLoading/Loading";

type ReusableDataGridProps = {
  searchText: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAddButtonClick: () => void;
  addButtonName: string;
  rows: any[];
  columns: GridColDef[];
  loading: any;
};

const ReusableTable: React.FC<ReusableDataGridProps> = ({
  searchText,
  onSearchChange,
  onAddButtonClick,
  addButtonName,
  rows,
  columns,
  loading,
}) => {
  const CustomNoRowsOverlay = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100%",
          width: "100%",
        }}
      >
        {loading ? (
          <Loading isLoading={loading} />
        ) : (
          <Typography variant="h5">No items available.</Typography>
        )}
      </div>
    );
  };
  return (
    <Box pt={6} pb={3}>
      <Card>
        <Box
          p={3}
          lineHeight={1}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <TextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={onSearchChange}
          />

          <ButtonAdd onClick={onAddButtonClick} name={addButtonName} />
        </Box>

        <Box
          style={
            rows.length === 0
              ? { height: 300, width: "100%", position: "relative" }
              : null
          }
        >
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            hideFooterSelectedRowCount
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default ReusableTable;
