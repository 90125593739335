import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { baseUrl } from "utils/baseUrl";

interface SendDataParams {
  endpoint: string;
  data: any; // Ideally, this should be a specific type or generic type instead of 'any'
  isJson?: boolean;
  method?: "get" | "post" | "put"; // and so on for other HTTP methods
}

export default function useSendData() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setSendError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const sendData = async ({
    endpoint,
    data,
    isJson = false,
    method,
  }: SendDataParams) => {
    setIsLoading(true);
    setIsSuccess(false);
    const token = localStorage.getItem("userToken");
    const headers: { [key: string]: string } = {};

    let sendData = data;

    if (isJson) {
      headers["Content-Type"] = "application/json";
      sendData = JSON.stringify(data);
    }

    // headers[token] = token;

    try {
      const response = await axios({
        url: `${baseUrl}/${endpoint}`,
        method: method,
        // headers: headers,
        headers: {
          ...headers,
          // token,
          Authorization: `Bearer ${token}`,
        },
        data: sendData,
        // headers: {
        //   "Content-Type": "multipart/form-data",
        //   token,
        // },
        // data: formData,
      });
      // console.log("Server response:", response);
      if (response.data && response.data.message) {
        toast.success(response.data.message);
      } else {
        toast.success("Operation successful");
      }
      setIsSuccess(true);
      return response.data; // Returning the response data for further processing
    } catch (error: any) {
      toast.error(error.response.data.message);
      console.error("Error sending data:", error.response.data.message);
      let errorMessage = "An error occurred while send data.";
      if (axios.isAxiosError(error) && error.response && error.response.data) {
        errorMessage = error.response.data.message || error.message;
        toast.error(errorMessage);
      } else if (error instanceof Error) {
        errorMessage = error.message;
        toast.error(errorMessage);
      }
      setSendError(errorMessage);
    } finally {
      // setData(false);
      setIsLoading(false);
    }
  };

  return { sendData, isSuccess, error, isLoading };
}
