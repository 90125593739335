import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ConfirmDelete from "Components/Axios/ConfirmDelete";
import ButtonAdd from "Components/ButtonAdd/ButtonAdd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "utils/baseUrl";
import AddVendorGroup from "./AddVendorGroup/AddVendorGroup";
import UpdateVendorGroup from "./UpdateVendorGroup/UpdateVendorGroup";
// import AddFoodItem from "./AddFoodItem/AddFoodItem";
// import UpdateFoodItem from "./UpdateFoodItem/UpdateFoodItem";

interface Category {
  id: string;
  name: string;
  description: string;
  price: string;
}

export default function VendorsGroup() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [searchText, setSearchText] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        let token = localStorage.getItem("userToken");

        const response = await axios.get(`${baseUrl}/vendor-groups`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setCategories(response.data);
      } catch (error) {
        toast.error("Error fetching Vendor Group");
      }
    };

    fetchCategories();
  }, []);

  const handleOpenAddDialog = () => setOpenAddDialog(true);
  const handleCloseAddDialog = () => setOpenAddDialog(false);

  const handleOpenEditDialog = (category: Category) => {
    setSelectedCategory(category);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => setOpenEditDialog(false);

  const handleOpenDeleteDialog = (category: Category) => {
    setSelectedCategory(category);
    setOpenDeleteDialog(true);
  };

  const onUpdateSuccess = (updatedCategory: any) => {
    // First, check if updatedCategory is not undefined
    if (!updatedCategory) {
      console.error("Updated category data is undefined");
      return;
    }

    // Proceed with your logic if updatedCategory is defined
    setCategories((currentCategories) =>
      currentCategories.map((category) =>
        category.id === updatedCategory.id ? updatedCategory : category
      )
    );
    setOpenEditDialog(false);
  };

  const handleCategoryClick = (id: any) => {
    navigate(`/vendorsgroup/${id}`);
  };

  const handleAddCategorySuccess = (newCategory: any) => {
    setCategories([...categories, newCategory]);
  };

  if (!categories) {
    return <div>Loading...</div>; 
  }
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <ButtonAdd
            onClick={handleOpenAddDialog}
            name="Add New Vendor group"
          />
        </Box>

        <Grid
          container
          spacing={2}
          sx={{
            minWidth: "63vw",
            "& > .MuiGrid-item": {
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              minWidth: "300px",
            },
          }}
        >
          {categories
            .filter((category) =>
              category?.name.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((category) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={category.id}>
                <Card
                  sx={{
                    bgcolor: (theme) => theme.palette.custom?.card,
                  }}
                >
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {category.name}
                    </Typography>
                    {/* <Typography variant="body2" color="text.secondary">
                      {category.description}
                    </Typography> */}
                  </CardContent>
                  <Grid container justifyContent="flex-end">
                    <IconButton
                      onClick={() => handleCategoryClick(category.id)}
                    >
                      <VisibilityIcon />
                    </IconButton>

                    <IconButton onClick={() => handleOpenEditDialog(category)}>
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      onClick={() => handleOpenDeleteDialog(category)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Box>

      <AddVendorGroup
        open={openAddDialog}
        handleClose={handleCloseAddDialog}
        onAddSupply={handleAddCategorySuccess}
      />

      {openDeleteDialog && selectedCategory && (
        <ConfirmDelete
          itemName={selectedCategory.name}
          endpoint={`vendor-groups/${selectedCategory.id}`}
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onDeleted={() => {
            setCategories(
              categories.filter((supply) => supply.id !== selectedCategory.id)
            );
            setOpenDeleteDialog(false);
          }}
        />
      )}

      {selectedCategory && (
        <UpdateVendorGroup
          open={openEditDialog}
          selectedCategory={selectedCategory}
          handleClose={handleCloseEditDialog}
          onUpdateSuccess={onUpdateSuccess}
        />
      )}
    </>
  );
}
